import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import { DatePicker } from "antd";
import {
  numberFormat,
  TokenExpiry,
  selectDropdownStyle,
  firstDayOfMonth, lastDayOfMonth
} from "../../../Helpers/Utils/Common";
import { searchProject } from "./../../../Helpers/apiCalls/Manage/Projects";
import { getAllCustomer } from "./../../../Helpers/apiCalls/Manage/CustomerAPI";
import { getProjectSalesReport } from "../../../Helpers/apiCalls/SalesReport/SalesReportApi";
import moment from "moment";
import { CSVLink } from "react-csv";
import ExportPdf from "../../../Components/Export/ExportPdf";

const {RangePicker} = DatePicker;

export default function MonthlyReport() {
  const [inactive, setInactive] = useState(true);

  const [filterConfig, setFilterConfig] = useState({
    date_from: firstDayOfMonth(),
    date_to: lastDayOfMonth(),
    payment_status: "paid",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);

  const excelHeaders = [
    { label: "Date", key: "project_date" },
    { label: "Customer", key: "customer_name" },
    { label: "Project", key: "name" },
    { label: "amount", key: "amount" },
    { label: "Total Paid", key: "total_paid" },
    { label: "Balance", key: "balance" },
  ];
  const pdfHeaders = [
    "Date",
    "Customer",
    "Project",
    "Amount",
    "Total paid",
    "Balance",
  ];

  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([]);

    const response = await getAllCustomer(filterConfig);
    if (response.error) {
      TokenExpiry(response.error);
    } else {
      var projects = response.data.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{ label: "All Customers", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchProjects() {
    setProjects([]);

    const response = await searchProject();
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error.data.status);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setTableData([])
    setTotalReceivables(0)
    const response = await getProjectSalesReport(filterConfig);
    if (response.data) {
      var tempTotal = 0;
      var data = response.data.data?.map((data) => {
        tempTotal += parseFloat(data.paid_amount)
        var tempInvoice = data.payment_date?`${moment(data.payment_date).format('YYYY')}-${data.project_id}`:'';
        return {
          remarks: data.remarks,
          invoice_no: tempInvoice,
          payment_date: data.payment_date?moment(data.payment_date).format('YYYY-MM-DD'):'',
          customer_name: data.customer_name,
          name: data.project_name,
          amount: numberFormat(data.paid_amount),
        };
      });

      data.sort((a, b) => {
          if (a.payment_date < b.payment_date) return -1;
          if (a.payment_date > b.payment_date) return 1;
          return 0;
      });

      setTotalReceivables(tempTotal)
      setTableData(data)
    }

    setShowLoader(false);
  }

  function handleToCSV() {
    return (
      <CSVLink
        data={tableData}
        headers={excelHeaders}
        filename={"SalesReport.csv"}
        style={{ textDecoration: "none", color: "#ffffff" }}
      >
        Export to CSV
      </CSVLink>
    );
  }
  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    fetchCustomers();
    fetchProjects();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Sales Report </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button className="add-btn text-center">{handleToCSV()}</button>
            <ExportPdf
              name={"SalesReport"}
              data={tableData}
              header={pdfHeaders}
            ></ExportPdf>
          </Col>
        </Row>

        <div className="tab-content">
          {/* filters */}
          <div className="my-2 px-2 PO-filters d-flex">
            <span className="me-1 align-middle mt-2 ps-label">Filter By:</span>
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customers"
              styles={selectDropdownStyle}
              // value={branch}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value };
                });
              }}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Project"
              styles={selectDropdownStyle}
              // value={branch}
              options={projects}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, project_id: e.value };
                });
              }}
            />
            <RangePicker placeholder={[filterConfig.date_from, filterConfig.date_to]} onChange={(e) => {
              if (e) {
                setFilterConfig((prev) => ({...prev,
                  date_from: e[0].format('YYYY-MM-DD'),
                  date_to: e[1].format('YYYY-MM-DD'),
                }))
              } else {
                setFilterConfig((prev) => ({...prev,
                  date_from: firstDayOfMonth(),
                  date_to: lastDayOfMonth(),
                }))
              }
            }}/>
          </div>

          <div className="my-2 px-2 PO-filters d-flex justify-content-end">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Sales: {numberFormat(totalReceivables)}
            </span>
          </div>

          <div className="">
            {/* table */}
            <Table
              tableHeaders={[
                "PAYMENT DATE",
                "INVOICE NO",
                "PROJECT",
                "CUSTOMER",
                "DESCRIPTION",
                "PAID AMOUNT",
              ]}
              headerSelector={[
                "payment_date",
                "invoice_no",
                "name",
                "customer_name",
                "remarks",
                "amount",
              ]}
              tableData={tableData}
              showLoader={showLoader}
            />
          </div>
          <div className="mb-2" />
        </div>
      </div>
    </div>
  );
}
