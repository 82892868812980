import moment from "moment";
import React, { useState } from "react";
import { Container} from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import cleanLogo from "../../Assets/Images/Login/logo.png";
import Navbar from "../../Components/Navbar/Navbar";
import PaymentTable from "./PaymentTable";
import {
  isAdmin,
  numberFormat,
  refreshPage, 
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common";
import { getProjectInvoice } from "./../../Helpers/apiCalls/ProjectInvoice/ProjectInvoiceApi";
import "./ProjectInvoice.css";

export default function PrintProjectInvoice() {
  const { id } = useParams(); // id
  let navigate = useNavigate();

  const [inactive, setInactive] = useState(true);
  const [addDetails, setAddDetails] = useState([]);
  const [items, setItems] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  async function fetchProjectInvoice() {
    setAddDetails({});
    setPaymentInfo([]);
    setItems([]);

    const response = await getProjectInvoice(id);

    if (response.error) {
      TokenExpiry(response);
    } else {
      var details = response.data.data[0];
      setAddDetails(details);

      setItems(details.project_invoice_items);

      var payment = details.project_invoice_payments?.map((data) => {
        if (data.paid_amount !== "0.00") {
          var info = data;
          info.paid_amount = numberFormat(data.paid_amount);
          if (data.payment_type === "check") {
            info.payment_type = data.payment_type + "-" + data.cheque_number;
          }
          setPaymentInfo((prev) => [...prev, info]);
          return info;
        }
      });
    }
    // handlePrint()
  }

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() });
    setTimeout(() => {
      toast.dismiss();
      Print();
    }, 1000);
  }
  const firstTable = () => {
  return (
    <div className="app-container">
      <table>
        <thead>
          <tr>
            <th
              className="text-left custom-td"
              colSpan="2"
              style={{ fontWeight: "bold", fontSize: "25px" }} // Increased font size
            >
              Billing Details:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}> {/* Increased font size */}
              Company
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.company}
            </td>
          </tr>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}>
              Project Name
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.project_name}
            </td>
          </tr>
          <tr>
            <td className="text-left custom-td" style={{ fontSize: "20px" }}>
              Address
            </td>
            <td className="print-data text-left custom-td" style={{ fontSize: "20px" }}>
              {addDetails.address}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const secondTable = () => {
  return (
    <div className="app-container">
      <table>
        <thead>
          <tr>
            <th
              className="custom-td"
              style={{ fontWeight: "bold", fontSize: "20px", width: "45%" }} // Increased font size
            >
              Product/Description
            </th>
            <th
              className="custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              Qty
            </th>
            <th
              className="custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              Unit
            </th>
            <th
              className="custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              Price
            </th>
            <th
              className="custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                <td className="custom-desc-td" style={{ fontSize: "18px" }}>
                  {item.item_name}
                </td>
                <td className="custom-num-td" style={{ fontSize: "18px" }}>
                  {item.qty}
                </td>
                <td className="text-lowercase custom-desc-td" style={{ fontSize: "18px" }}>
                  {item.unit}
                </td>
                <td className="custom-num-td" style={{ fontSize: "18px", color: "black" }}>
                  {numberFormat(item.price)}
                </td>
                <td className="custom-num-td" style={{ fontSize: "18px", color: "black" }}>
                  {numberFormat(item.subtotal)}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={3}></td>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" }}
            >
              Sub Total
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.subtotal ? numberFormat(addDetails.subtotal) : "0.00"}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" }}
            >
              12% VAT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.vat_twelve
                ? numberFormat(parseFloat(addDetails.vat_twelve).toFixed(2))
                : 0}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" }}
            >
              NET OF VAT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.vat_net
                ? numberFormat(parseFloat(addDetails.vat_net).toFixed(2))
                : 0}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td
              className="text-end custom-td"
              style={{ fontSize: "18px" }}
            >
              WHT
            </td>
            <td
              className="text-right custom-td"
              style={{ fontSize: "18px" }}
            >
              PHP{" "}
              {addDetails.wht
                ? numberFormat(parseFloat(addDetails.wht).toFixed(2))
                : "0.00"}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td
              className="text-end custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              GRAND TOTAL
            </td>
            <td
              className="text-right custom-td"
              style={{ fontWeight: "bold", fontSize: "20px" }} // Increased font size
            >
              PHP{" "}
              {addDetails.grand_total
                ? numberFormat(addDetails.grand_total)
                : "0.00"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

  


  function sentenceCase(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print(printContents);
    document.body.innerHTML = originalContents;
    refreshPage();
  }
  React.useEffect(() => {
    fetchProjectInvoice();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FRANCHISE"}
        />
      </div>
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
        {/* Add print styles inline or in the <style> block below */}
          <div
            className={
              addDetails.fs_status === "invoiced"
                ? "text-end print-header d-flex flex-column"
                : "text-start print-header d-flex flex-column"
            }
          ></div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left">
                <img
                  src={cleanLogo}
                  className="print-logo"
                  alt="Logo"
                  style={{ width: "200px", height: "70px" }}
                />
              </div>
              <div className="text-end">
                <div>
                  <span
                    style={{
                      fontSize: "40px",
                      color: "#5ac8e1",
                      fontWeight: "bold",
                    }}
                  >
                    INVOICE
                  </span>
                </div>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                  MYT SoftDev Solutions, Inc.
                </span>
                <span style={{ fontSize: "30px", fontWeight: "bold" }}>
                  NO. {id}
                </span>
              </div>
            </div>

            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: "16px" }}>
                  301 The Greenery, Pope John Paul II Ave.
                </span>
              </div>
            </div>

            <div style={{ fontSize: "16px" }}>Cebu City, 6000 Cebu</div>

            {/* Add Billing Date and Due Date */}
            <div className="text-left mt-3">
              <div className="d-flex justify-content-between">
              <span></span>
              <span className="text-uppercase" style={{ fontSize: "18px"}}>
                {"BILLING DATE: " +
                  (addDetails.invoice_date
                    ? moment(addDetails.invoice_date).format("MMMM DD, yyyy")
                    : "N/A")}
              </span>

              </div>
              <div className="d-flex justify-content-between">
                <span></span>
                <span style={{ fontSize: "18px" }}>
                  {"DUE DATE: " +
                    (addDetails.due_date && addDetails.due_date !== "0000-00-00"
                      ? moment(addDetails.due_date).format("MMMM DD, yyyy")
                      : "N/A")}
                </span>

              </div>
            </div>

          </div>
          <div className="d-flex mt-5 mb-2">
            <div className="print-table mt-3 mx-2">{firstTable()}</div>
          </div>
          <div className="d-flex mt-5 mb-2">
            <div className="print-table mt-3 mx-2">{secondTable()}</div>
          </div>
          <div className="text-left" style={{ fontSize: "20px", textTransform: "uppercase" }}>
            REMARKS:{" "}
            {addDetails && addDetails.remarks !== undefined && addDetails.remarks !== null && addDetails.remarks !== "undefined"
              ? addDetails.remarks
              : ""}
          </div>


        <div
          className="text-left"
          style={{ fontSize: "20px", paddingTop: "20px", paddingBottom: "20px" }} // Set fontSize to 18px for consistency
        >
          <span>
            For check payment, deposit payment to:
            <div>Bank Name: Banco de Oro (BDO) Cebu HM Tower</div>
            <div>Account Number: 013030014422</div>
            <div>Account Name: MYT SoftDev Solutions, Inc.</div>
          </span>
        </div>


          <div>
      
          </div>
        </div>

        <Container
          fluid
          className="PI-payment-info-wrapper mt-5 py-3 px-3 edit-form"
        >
          <h5 className="PI-payment-info">PAYMENT HISTORY</h5>
          <div className="sales-tbl justify-content-center">
            <PaymentTable
              tableHeaders={[
                "PYMT DATE",
                "INV NO.",
                // "PARTICULARS",
                "TYPE",
                "PAID AMT",
                "DEPOSIT DATE",
                "DEPOSITED TO",
                "REMARKS",
              ]}
              headerSelector={[
                "payment_date",
                "invoice_no",
                // "particulars",
                "payment_type",
                "paid_amount",
                "deposit_date",
                "to_bank_name",
                "remarks",
              ]}
              tableData={paymentInfo}
            />
          </div>
        </Container>

        {/* footer */}
        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/projectinvoice")}
          >
            Close
          </button>
          {addDetails.payment_status === "open_bill" && isAdmin() ? (
            <button
              className="button-tertiary me-3"
              onClick={() => navigate("/projectinvoice/edit/" + id)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
}
