import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/Table";
import { numberFormat, getTodayDateISO } from "../../Helpers/Utils/Common";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import { CSVLink } from "react-csv";
import downloadIcon from "../../Assets/Images/download_icon.png";
import { getFinancialReport } from "../../Helpers/apiCalls/SalesReport/SalesReportApi";
import moment from "moment";
import dayjs from "dayjs";
export default function IncomeStatementReport() {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });
  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [reportSummary, setReportSummary] = useState({});
  // const [totalExpense, setTotalExpense] = useState(0);
  // const [totalIncome, setTotalIncome] = useState(0);
  // const [totalProfit, setTotalProfi] = useState(0);

  async function fetchTableData() {
    setShowLoader(true);
    setTableData([]);
    const response = await getFinancialReport(filterConfig);
    if (response.data) {
      // setTableData(response.data.financial_report)
      const res = response.data?.financial_report?.map((row) => {
        return {
          ...row,
          income: numberFormat(row.incom),
          expense: numberFormat(row.expense),
        };
      });
      setTableData(res);
      setReportSummary(response.data.summary);
    }
    setShowLoader(false);
  }

  React.useEffect(() => {
    fetchTableData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"FINANCIAL REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> INCOME STATEMENT </h1>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end mb-3">
            <div className="justify-content-center align-items-center ">
              <CSVLink
                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                data={tableData}
                target="_blank"
                filename={`${getTodayDateISO()} Income Statement Report`}
              >
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                Download Excel
              </CSVLink>
            </div>
          </Col>
        </Row>

        <div className="tab-content mt-2">
          <div className="my-2 px-2 PO-filters d-flex">
            <span className="me-1 align-middle mt-2 ps-label">From:</span>
            <DatePicker
              value={filterConfig.date_from ? dayjs(filterConfig.date_from, 'YYYY-MM-DD') : null} // Using dayjs to format date
              name="date_from"
              placeholder="Select Date"
              onChange={(date, dateString) => {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_from: dateString // The date will be saved in YYYY-MM-DD format
                }));
              }}
              className="PI-date-btn me-3 form-control"
              format="YYYY-MM-DD"
              style={{ width: 200 }}
              picker="date" // Disable hover-to-select
            />

            <span className="me-1 align-middle mt-2 ps-label">To:</span>
            <DatePicker
              value={filterConfig.date_to ? dayjs(filterConfig.date_to, 'YYYY-MM-DD') : null} // Using dayjs to format date
              name="date_to"
              placeholder="Select Date"
              onChange={(date, dateString) => {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_to: dateString // The date will be saved in YYYY-MM-DD format
                }));
              }}
              className="PI-date-btn me-3 form-control"
              format="YYYY-MM-DD"
              style={{ width: 200 }}
              picker="date" // Disable hover-to-select
            />
          </div>
          <div className="mb-2 mt-4 px-2 PO-filters d-flex justify-content-center">
            <span className="me-4 ml-4 align-middle ps-label">
              Total Income: {numberFormat(reportSummary?.total_income)}
            </span>
            <span className="me-4 ml-4 align-middle ps-label">
              Total Expense: {numberFormat(reportSummary?.total_expense)}
            </span>

            <span className="me-4 ml-4 align-middle ps-label">
              Total Profit: {numberFormat(reportSummary?.total_profit)}
            </span>
          </div>

          <div className="below">
            {/* table */}
            <Table
              tableHeaders={[
                "DATE",
                "REFERENCE",
                "DESCRIPTION",
                "ACCOUNT TYPE",
                "INCOME",
                "EXPENSE",
              ]}
              headerSelector={[
                "date",
                "reference",
                "description",
                "account_type",
                "income",
                "expense",
              ]}
              tableData={tableData}
              showLoader={showLoader}
            />
          </div>
          <div className="mb-2" />
        </div>
        <div className="d-flex justify-content-end pt-5 pb-3">
          <button
            type="button"
            className="button-secondary me-3"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
