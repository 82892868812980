/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { refreshPage, removeUserSession } from "../../Helpers/Utils/Common";
import { getName, getType, getRole } from "../../Helpers/Utils/Common";
import MenuItem from "./MenuItem";
import { logoutUser } from "../../Helpers/apiCalls/authApi";
import { toastStyle } from "../../Helpers/Utils/Common";

import toast from "react-hot-toast";

//css
import "../Navbar/Navbar.css";

//icons
import logout from "../../Assets/Images/Navbar/logout.png";
import logo from "../../Assets/Images/Login/mytlogo.png";
import supplies from "../../Assets/Images/Navbar/supplies.png";
import sales from "../../Assets/Images/Navbar/sales.png";
import cp from "../../Assets/Images/Navbar/cp.png";
import reports from "../../Assets/Images/Navbar/reports.png";
import storeReport from "../../Assets/Images/Navbar/storeReport.png";
import manage from "../../Assets/Images/Navbar/manage.png";
import employee from "../../Assets/Images/Navbar/employee.png";
import pettycashicon from "../../Assets/Images/Navbar/pettycash.png";



const Navbar = (props) => {
  //ADMIN
  const adminMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "SALES",
      exact: true,
      to: "/",
      iconClassName: sales,
      subMenus: [
        { name: "Project Register", to: "/project" },
        { name: "Project Sales Invoice", to: "/projectinvoice" },
        { name: "Project Sales", to: "/projectsales" },
        // { name: "Distributor Billings", to: "/distributor_billings" },
        // { name: "Subscription Billings", to: "/subscription_billings" },
      ],
      expand: true,
    },
    {
      name: "EXPENSE",
      exact: true,
      to: "/",
      iconClassName: supplies,
      subMenus: [
        { name: "Supplies PO", to: "/suppliesexpenses" },
        {
          name: "Supplies Invoice",
          to: "/se/purchaseinvoices",
        },
        { name: "Pay supplier", to: "/se/paysuppliers" },
        { name: "Project Expense", to: "/projectexpense" },
      ],
      expand: true,
    },
    {
      name: "PETTY CASH",
      exact: true,
      to: "/",
      iconClassName: pettycashicon,
      subMenus: [{ name: "Petty Cash Register", to: "/pettycash" }],
      expand: true,
    },

    {
      name: "SALES REPORT",
      exact: true,
      to: "/",
      iconClassName: reports,
      subMenus: [
        { name: "Sales Report", to: "/salesreport" },
        { name: "Receivables Aging Report", to: "/receivablesagingreport" }
      ],
      expand: true,
    },

    {
      name: "EXPENSES REPORT",
      exact: true,
      to: "/",
      iconClassName: storeReport,
      subMenus: [
        // { name: "Payables Aging Report", to: "/payablesagingreport" },
        { name: "Expense Report", to: "/expensebytype" },
        // { name: "Expense By Date", to: "/expensebydate" }
      ],
      expand: true,
    },
    {
      name: "FINANCIAL REPORT",
      exact: true,
      to: "/",
      iconClassName: storeReport,
      subMenus: [
        { name: "Income Statement", to: "/incomestatementreport" },
      ],
      expand: true,
    },

    {
      name: "MANAGE",
      exact: true,
      to: "/",
      iconClassName: manage,
      subMenus: [
        { name: "Suppliers", to: "/suppliers" },
        { name: "Banks", to: "/banks" },
        { name: "Customer", to: "/customer" },
        { name: "Forwarders", to: "/forwarders" },
        { name: "Account Type ", to: "/expensetypes" },
        { name: "Users", to: "/users" },
        { name: "Distributors", to: "/distributors" },
      ],
      expand: true,
    },
  ];

  // logout handler
  async function handleLogout() {
    const response = await logoutUser();
    removeUserSession();
    window.localStorage.clear();
    toast.success("Logging you out...", {
      style: toastStyle(),
    });
    setTimeout(() => refreshPage(), 1000);
  }

  const [inactive, setInactive] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [expandManage, setExpandManage] = useState(true);

  const handleExpand = (index) => {
    var list = [...menuItems];

    if (!inactive) {
      for (var i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i]["expand"] = true;
        }
      }

      list[index]["expand"] = !list[index]["expand"];

      setMenuItems(list);
    }
  };

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    const type = getType();
    switch (type) {
      case "admin":
        setMenuItems(adminMenu);
        break;
      default:
        break;
    }

    let menuItemsList = document.querySelectorAll(".menu-item");
    menuItemsList.forEach((el, index) => {
      if (menuItems[index].name == props.active) {
        el.classList.add("active");
      }
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItemsList.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (
    <div className={`side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        <div className="logo d-flex justify-content-center">
          <img
            src={logo}
            className="navbar-logo"
            onClick={() => setInactive(!inactive)}
          />
        </div>
        {/* <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? (
            <div className="max-menu-cont">
              <FontAwesomeIcon
                icon={"angle-double-right"}
                alt={"open"}
                className={"max-menu"}
                aria-hidden="true"
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={"angle-double-left"}
              alt={"close"}
              className={"min-menu"}
              aria-hidden="true"
            />
          )}
        </div> */}
      </div>

      <div className={inactive ? "main-menu" : "main-menu active-menu"}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            name={menuItem.name}
            exact={menuItem.exact.toString()}
            to={menuItem.to}
            subMenus={menuItem.subMenus || []}
            iconClassName={menuItem.iconClassName}
            expandManage={menuItem.expand}
            setExpandManage={handleExpand}
            index={index}
            activeSub={menuItem.name === props.active}
            onClick={(e) => {
              if (inactive) {
                setInactive(false);
              }
            }}
          />
        ))}
      </div>
      <div className="side-menu-footer">
        {!inactive && (
          <div className="user-details-footer">
            {/* <div className="account-label">Account</div> */}
            <span className="navbar-user-label">{getName()}</span>
            <br />
            <span className="user-type-label">{getType()}</span>
          </div>
        )}
        <div className="logout-cont" onClick={() => handleLogout()}>
          <img src={logout} className="logout-btn" />
          <span className="logout-label">LOGOUT</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
