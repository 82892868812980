import { getToken, getUser, getTime12 } from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";

//GET
export const getAllProjects = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_all_project",
      {
        requester: getUser(),
        token: getToken(),
      }
    );
    return { data: response };
  } catch (error) {
    return { error: error.response };
  }
};

// GET SINGLE
export const getProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchProject = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/search",
      {
        requester: getUser(),
        token: getToken(),
        ...data
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchProjectStatus = async (filterConfig) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_operation_logs/get_all",
      {
        requester: getUser(),
        token: getToken(),
        status: filterConfig.status,
        branch_type: filterConfig.branch_type,
        project_name: filterConfig.project_name,
        project_id: filterConfig.project_id,
        date: filterConfig.date_from
          ? Moment(filterConfig.date_from).format("YYYY-MM-DD")
          : "",
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

//POST
export const createProject = async (data,recurringData, costData) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "projects/create",
      {
        requester: getUser(),
        token: getToken(),
        // ...data,
        name: data.name,
        distributor_id: data.distributor_id,
        project_date: data.project_date,
        start_date: data.start_date,
        customer_id: data.customer_id,
        address: data.address,
        company: data.company,
        contact_person: data.contact_person,
        contact_number: data.contact_number,
        renewal_date: data.renewal_date,
        payment_structure: data.payment_structure,
        vat_type: data.vat_type,
        vat_twelve: data.vat_twelve,
        vat_net: data.vat_net,
        withholding_tax: data.withholding_tax,
        grand_total: data.grand_total,
        recurring_cost_total: data.recurring_cost_total,
        descriptions: recurringData.map(item => item.description),
        types:recurringData.map(item => item.type),
        periods: recurringData.map(item => item.periods),
        prices: recurringData.map(item => item.prices),
        project_one_time_fee_description: costData.map((item) => item.description),
        project_one_time_fee_amount: costData.map((item) => item.amount),
        project_one_time_fee_type: costData.map((item) => item.type),
        project_one_time_fee_period: costData.map((item) => item.period),
        project_type_name_id: data.project_type,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const updateProject = async (data,recurringData,costData) => {
  try {
    const response = await postAPICall(
      process.env.REACT_APP_LINK + "projects/update",
      {
        requester: getUser(),
        token: getToken(),
        // ...data,
        project_id: data.id,
        name: data.name,
        distributor_id: data.distributor_id,
        project_date: data.project_date,
        start_date: data.start_date,
        customer_id: data.customer_id,
        address: data.address,
        company: data.company,
        contact_person: data.contact_person,
        contact_number: data.contact_number,
        renewal_date: data.renewal_date,
        payment_structure: data.payment_structure,
        vat_type: data.vat_type,
        vat_twelve: data.vat_twelve,
        vat_net: data.vat_net,
        withholding_tax: data.withholding_tax,
        grand_total: data.grand_total,
        recurring_cost_total: data.recurring_cost_total,
        descriptions: recurringData.map(item => item.description),
        ids: recurringData.map(item => item.id),
        types:recurringData.map(item => item.type),
        periods: recurringData.map(item => item.periods),
        prices: recurringData.map(item => item.prices),
        project_one_time_fee_id: costData.map((item) => item.id),
        project_one_time_fee_description: costData.map((item) => item.description),
        project_one_time_fee_type: costData.map((item) => item.type),
        project_one_time_fee_period: costData.map((item) => item.period),
        project_one_time_fee_amount: costData.map((item) => item.amount),
        project_type_name_id: data.project_type,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const searchProjectSales = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "reports/get_project_sales",
      {
        requester: getUser(),
        token: getToken(),
        ...data,
        // date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
        // date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
        // project_id: data.project_id,
        // customer_id: data.customer_id,
      }
    );
    return { data: response.data };
  } catch (error) {
    return { error: error.response };
  }
};

export const getProjectTypes = async () => {
  try {
      const response = await getAPICall(
          process.env.REACT_APP_LINK + "projects/get_project_type_names",
          {
              requester: getUser(),
              token: getToken(),
          }
      )
      return {data: response.data}
  } catch (error) {
      return {error: error}
  }
}