import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Select } from "antd";
// import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import Moment from "moment";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import NoDataImg from "./../../../Assets/Images/no-data-dog.png";
import "../ExpensesReport.css";
import { searchExpenseByType } from "./../../../Helpers/apiCalls/ExpensesReports/ExpenseReportApi";
import { getAllExpenseType } from "../../../Helpers/apiCalls/expensetypesApi";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

const background = [
  "#FFB3BA", // Pastel Red
  "#FFDFBA", // Pastel Orange
  "#FFFFBA", // Pastel Yellow
  "#BAFFC9", // Pastel Green
  "#BAE1FF", // Pastel Blue
  "#E6B3FF", // Pastel Purple
  "#FFCCE5", // Pastel Pink
  "#D4A5A5", // Light Pastel Pink
  "#C1C8E4", // Pastel Lavender
  "#FFD6A5", // Light Pastel Orange
  "#B5EAD7", // Pastel Mint Green
  "#C7CEEA", // Pastel Periwinkle
  "#FFDAC1", // Pastel Peach
  "#E2F0CB", // Light Pastel Lime Green
  "#FFB7B2", // Light Pastel Salmon
  "#D5E1DF", // Light Pastel Teal
  "#F3E2E2", // Light Pastel Pinkish White
  "#FAD9C1", // Light Pastel Peach
  "#FFC4C4", // Light Pastel Coral
  "#E4C1F9", // Pastel Lilac
];

const border = [
  "#FF5C73", // Darker shade of Pastel Red
  "#FFB066", // Darker shade of Pastel Orange
  "#FFFF66", // Darker shade of Pastel Yellow
  "#66FF99", // Darker shade of Pastel Green
  "#66B3FF", // Darker shade of Pastel Blue
  "#CC66FF", // Darker shade of Pastel Purple
  "#FF99CC", // Darker shade of Pastel Pink
  "#A65252", // Darker shade of Light Pastel Pink
  "#8B99D2", // Darker shade of Pastel Lavender
  "#FFA552", // Darker shade of Light Pastel Orange
  "#66D1AA", // Darker shade of Pastel Mint Green
  "#8C99D2", // Darker shade of Pastel Periwinkle
  "#FFA474", // Darker shade of Pastel Peach
  "#A8D68C", // Darker shade of Light Pastel Lime Green
  "#FF6660", // Darker shade of Light Pastel Salmon
  "#A1C8C3", // Darker shade of Light Pastel Teal
  "#D9B3B3", // Darker shade of Light Pastel Pinkish White
  "#F5B08C", // Darker shade of Light Pastel Peach
  "#FF9999", // Darker shade of Light Pastel Coral
  "#C08CC8", // Darker shade of Pastel Lilac
];

export default function ExpenseByType() {
  let navigate = useNavigate();
  const dateToday = moment();
  const firstDayOfMonth = dateToday.startOf("month").format("YYYY-MM-DD");
  const lastDayOfMonth = dateToday.endOf("month").format("YYYY-MM-DD");

  const [inactive, setInactive] = useState(true);
  const [filterConfig, setFilterConfig] = useState({
    date_from: firstDayOfMonth,
    date_to: lastDayOfMonth,
  });
  const [noData, setNoData] = useState(true);
  const [expenseType, setExpenseType] = useState([]);
  const [amount, setAmount] = useState([]);
  //   const [background, setBackground] = useState([]);
  //   const [border, setBorder] = useState([]);
  const [allExpenseTypes, setAllExpenseTypes] = useState([]);
//   const [filteredTypes, setFilteredTypes] = useState("");

  const chartData = {
    labels: expenseType,
    datasets: [
      {
        label: "Expense By Type",
        data: amount,
        fill: true,
        backgroundColor: background,
        borderColor: border,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const data = tooltipItem.dataset.data;
            const total = data.reduce((acc, value) => acc + value, 0);
            const currentValue = data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            const label = tooltipItem.label || "";
            return `${label} (${percentage}%): ${currentValue.toLocaleString()}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  function setBg() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    var color = "#" + randomColor;
    return color;
  }

  async function fetchData() {
    const response = await searchExpenseByType(filterConfig);

    if (response.error) {
      setExpenseType([]);
      setAmount([]);
      setNoData(true);
    } else {
      setExpenseType(response.data.expense_type);
      setAmount(response.data.expense_total);
      setNoData(false);
      var bg = response.data.expense_type.map((date) => {
        var color = setBg();
        return color;
      });
      //   setBackground(bg);
      //   setBorder(bg);
    }
  }

//   const handleTypeFilter = (e) => {
//     if (e.value === "") {
//       setFilteredTypes("");
//       if (filteredTypes === "") {
//         fetchData();
//       }
//     } else {
//       setFilteredTypes(e.name);
//     }
//   };

  const generatePdf = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const text = "EXPENSE REPORT.";
      const pdf = new jsPDF();
      pdf.text(text, 5, 5);
      pdf.addImage(imgData, "PNG", 15, 15, 180, 60);
      pdf.save("Expensebytype.pdf");
    });
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  React.useEffect(() => {
    fetchData();
  }, [filterConfig]);

  React.useEffect(() => {
    async function fetchAllExpenseTypes() {
      const response = await getAllExpenseType();
      if (response.data) {
        const res = response.data.map((row) => {
          return {
            value: row.id,
            label: row.name,
          }
        });
        setAllExpenseTypes(res);
        // setFilteredTypes(res);
      }
    }

    fetchAllExpenseTypes();
  }, []);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSES REPORT"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> EXPENSE REPORT </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button className="add-btn text-center" onClick={generatePdf}>
              Export to PDF
            </button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end mb-3">
            <div className="justify-content-center align-items-center ">
              <button
                type="button"
                className="button-primary "
                onClick={() =>
                  navigate(
                    "/expensereport/" +
                      Moment(filterConfig.date_from).format("MM-DD-YYYY") +
                      "/" +
                      Moment(filterConfig.date_to).format("MM-DD-YYYY")
                  )
                }
              >
                View
              </button>
            </div>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 px-2 PO-filters d-flex">
            <span className="me-4 align-middle mt-2 ps-label">
              Filter By Date:
            </span>

            <span className="me-4 align-middle mt-2 ps-label">From:</span>
            <DatePicker
              placeholder={filterConfig.date_from}
              name="date_from"
              placeholderText={Moment().format("MM/DD/YYYY")}
              onChange={(date) => {
                if (date) {
                  setFilterConfig((prev) => {
                    return { ...prev, date_from: date.format("YYYY-MM-DD") };
                  });
                }
              }}
              className="PI-date-btn me-3 form-control"
            />

            <span className="me-4 align-middle mt-2 ps-label">To:</span>
            <DatePicker
              placeholder={filterConfig.date_to}
              name="date_to"
              placeholderText={Moment().format("MM/DD/YYYY")}
              onChange={(date) => {
                if (date) {
                  setFilterConfig((prev) => {
                    return { ...prev, date_to: date.format("YYYY-MM-DD") };
                  });
                }
              }}
              className="PI-date-btn me-3 form-control"
            />
            {/* <span className="me-4 ms-4 align-middle mt-2 ps-label">
              Expense Type:
            </span> */}
            <Col xs={4}>
            <Select
            placeholder="Select Expense Type"
              options={[{ label: "All", value: "" }, ...allExpenseTypes]}
              showSearch
              filterOption={(option, input) =>
                option.label?.toLowerCase().includes(input?.toLowerCase())
              }
              className="w-60"
              onChange={(e) => setFilterConfig((prev) => ({...prev, expense_type: e}))}
            />
            </Col>
          </div>

          <div
            className="below d-flex justify-content-center mb-5"
            id="divToPrint"
          >
            {!noData ? (
              <Row className="p-4">
                <Col
                  xs={12}
                  md={12}
                  lg={4}
                  className="d-flex justify-content-center"
                >
                  <Pie
                    style={{ width: '100%', height: '900px' }}
                    data={chartData}
                    options={chartOptions}
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={8}
                  className="d-flex justify-content-center"
                >
                  <Bar
                    style={{ width: '100%', height: '900px' }}
                    data={chartData}
                    options={chartOptions}
                  />
                </Col>
              </Row>
            
            ) : (
              <div className="no-data-cont">
                <img
                  src={NoDataImg}
                  alt="no data found"
                  className="no-data-small"
                />
                <span>Uh Oh! No data found.</span>
              </div>
            )}
          </div>
          <div className="mb-2" />
        </div>
      </div>
    </div>
  );
}
