import {handleValidationChange} from '../CommonValidation';

export const validatePayment = (data, setIsError) => {
    let isValid = true;

    console.log("Validating payment data:", data); // Log input data

    // Check if payment type is empty
    if (data.payment_type === "") {
        handleValidationChange("payment_type", true, setIsError);
        console.log("Validation failed for payment_type");
        isValid = false;
    } else {
        handleValidationChange("payment_type", false, setIsError);
    }

    // Check if payment date is empty
    if (data.payment_date === "") {
        handleValidationChange("payment_date", true, setIsError);
        console.log("Validation failed for payment_date");
        isValid = false;
    } else {
        handleValidationChange("payment_date", false, setIsError);
    }

    // Check if invoice number is empty
    if (data.invoice_no === "" || data.invoice_no === undefined) {
        handleValidationChange("invoice_no", true, setIsError);
        console.log("Validation failed for invoice_no");
        isValid = false;
    } else {
        handleValidationChange("invoice_no", false, setIsError);
    }

    // Check if paid amount is empty
    if (data.paid_amount === "" || data.paid_amount === undefined) {
        handleValidationChange("paid_amount", true, setIsError);
        console.log("Validation failed for paid_amount");
        isValid = false;
    } else {
        handleValidationChange("paid_amount", false, setIsError);
    }

    // Check if deposit date is empty
    if (data.deposit_date === "" || data.deposit_date === undefined) {
        handleValidationChange("deposit_date", true, setIsError);
        console.log("Validation failed for deposit_date");
        isValid = false;
    } else {
        handleValidationChange("deposit_date", false, setIsError);
    }

    // For `cash` payment type, skip validations for `to_bank_id` and check-related fields
    if (data.payment_type !== "cash") {
        // Validate `to_bank_id`
        if (data.to_bank_id === "" || data.to_bank_id === undefined) {
            handleValidationChange("to_bank_id", true, setIsError);
            console.log("Validation failed for to_bank_id");
            isValid = false;
        } else {
            handleValidationChange("to_bank_id", false, setIsError);
        }

        // Additional validations for `check` payment type
        if (data.payment_type === "check") {
            if (data.bank_name === "" || data.bank_name === undefined) {
                handleValidationChange("bank_name", true, setIsError);
                console.log("Validation failed for bank_name");
                isValid = false;
            } else {
                handleValidationChange("bank_name", false, setIsError);
            }

            if (data.check_number === "" || data.check_number === undefined) {
                handleValidationChange("check_number", true, setIsError);
                console.log("Validation failed for check_number");
                isValid = false;
            } else {
                handleValidationChange("check_number", false, setIsError);
            }

            if (data.check_date === "" || data.check_date === undefined) {
                handleValidationChange("check_date", true, setIsError);
                console.log("Validation failed for check_date");
                isValid = false;
            } else {
                handleValidationChange("check_date", false, setIsError);
            }
        }

        // Additional validation if payment type is "others"
        if (data.payment_type === "others" && (data.reference_number === "" || data.reference_number === undefined)) {
            handleValidationChange("reference_number", true, setIsError);
            isValid = false;
        } else {
            handleValidationChange("reference_number", false, setIsError);
        }
    }

    console.log("Final validation result:", isValid);
    return isValid;
};

  