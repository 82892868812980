import React, { useState, useEffect } from "react";
import { Col, Form, Row} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";

// api calls and utils
import {
  refreshPage,
  toastStyle,
  TokenExpiry,
  formatDateNoTime,
  getType,
  numberFormat,
  numberFormatInt,
  selectDropdownStyle,
} from "../../Helpers/Utils/Common";

// css
import "./Project.css";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import AddPaymentModal from "./AddPaymentModal";
import ConfirmPaymentModal from "./ConfirmPaymentModal";
import { searchProject, deleteProject } from './../../Helpers/apiCalls/Manage/Projects';
import { getAllCustomer } from './../../Helpers/apiCalls/Manage/CustomerAPI';
import { getDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";

/**
 *  Franchise Register component
 */

export default function Project() {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [allData, setAllData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allDistributors, setAllDistributors] = useState([])
  const [showLoader, setShowLoader] = useState(false);
  /* add payment modal handler */
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const handleShowAddPaymentModal = () => setShowAddPaymentModal(true);
  const handleCloseAddPaymentModal = () => setShowAddPaymentModal(false);

  /* add payment modal handler */
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const handleShowConfirmPaymentModal = () => setShowConfirmPaymentModal(true);
  const handleCloseConfirmPaymentModal = () =>
    setShowConfirmPaymentModal(false);

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [projectId, setProjectId] = useState("");
  const [balance, setBalance] = useState("");
  const [franchiseeID, setFranchiseeID] = useState("");

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    // franchise: "",
    // tabKey: "",
    // project_id: "",
    // franchised_on_from: "",
    // franchised_on_to: "",
  });
  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilterConfig((prev) => {
      return { ...prev, [name]: value };
    });
  }


  async function fetchCustomers() {
    setShowLoader(true);
    setCustomers([])

    const response = await getAllCustomer();
    if (response.error) {
      if(response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response.data.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setCustomers([{label: "All Customers", value: ""}, ...projects]);
    }
    setShowLoader(false);
  }

  async function fetchDistributors() {
    setAllDistributors([]);
    const response = await getDistributor();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{value: '', label: 'All Distributors'}, ...res]);
    }
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([])

    const response = await searchProject();
    if (response.error) {
      if(response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{label: "All Projects", value: ""}, ...projects]);      
    }

    setShowLoader(false);
  }

  async function fetchData() {
    setShowLoader(true);
    setAllData([]);

    const response = await searchProject(filterConfig);
    if (response.data) {

      var allData = response.data.data.map((data) => {
        var info = data;
        info.start_date = data.start_date !== "0000-00-00" ? formatDateNoTime(data.start_date) : "None";
        info.project_date = data.project_date !== "0000-00-00" ? formatDateNoTime(data.project_date) : "None";
        info.grand_total = numberFormat(data.grand_total);
        info.balance = numberFormat(data.balance);
        info.paid_amount = data.paid_amount ? numberFormat(data.paid_amount) : "0.00";
        return info;
      });
      setAllData(allData);

    } else if (response.error.data.status !== 404) {
      TokenExpiry(response.error);
    }
    setShowLoader(false);
  }

  async function handleDeletePI() {
    const response = await deleteProject(projectId);

    if (response.data) {
      toast.success("Project Deleted Successfully!", {
        style: toastStyle(),
      });
      setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Project", {
        style: toastStyle(),
      });
    }
  }

  function handleSelectChange(e, row, id, franchisee_id, balance, name) {
    if (e.target.value === "edit-pi") {
      navigate("edit/" + franchisee_id);
    } else if (e.target.value === "print-pi") {
      navigate("print/" + franchisee_id);
    }else if (e.target.value === "add-invoice") {
      navigate("/projectinvoice/add", {state: {name: {value: id, label: name, key: id},row}})
    } else if (e.target.value === "payment-pi") {
      setBalance(numberFormatInt(balance));
      setProjectId(id);
      setBalance(numberFormatInt(balance));
      setFranchiseeID(franchisee_id);
      if (balance === 0 || balance === "0.00") {
        handleShowConfirmPaymentModal();
      } else {
        handleShowAddPaymentModal();
      }
    } else if (e.target.value === "delete-pi") {
      setProjectId(franchisee_id);
      handleShowDeleteModal();
    } else if (e.target.value === "renew") {
      navigate("/project/renew/"+row.id);
    }
  }

  function ActionBtn(row, type) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          handleSelectChange(e, row, row.project_id, row.id, row.balance, row.name)
        }
      >
        <option value="" hidden selected>
          Select
        </option>
        {userType === "admin" ? (
          <option value="edit-pi" className="color-options">
            Edit
          </option>
        ) : null}
        <option value="add-invoice" className="color-options">
          Add Invoice
        </option>
        {
          row.renewal_status&&row.renewal_status==="For Renewal" &&
          <option value="renew" className="color-options">Renew</option>
        }
        <option value="print-pi" className="color-options">
          View
        </option>
        {userType === "admin" && (
          <option value="delete-pi" className="color-red">
            Delete
          </option>
        )}
      </Form.Select>
    );
  }

  useEffect(() => {
    fetchProjects();
    fetchCustomers();
    fetchDistributors();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"SALES"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT REGISTER</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search project..."
              value={filterConfig.name}
              onChange={(e) => handleFilterChange(e)}
              className="search-bar"
            />
            <button
              className="add-btn"
              onClick={() => navigate("/project/add")}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex">
            <span className="me-3 align-middle mt-2">FILTER BY:</span>

            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="All Projects"
              styles={selectDropdownStyle}
              // value={branch}
              options={projects}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "project_id": e.value };
              });}}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "customer_id": e.value };
              });}}
            />
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Distributor"
              styles={selectDropdownStyle}
              options={allDistributors}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "distributor_id": e.value };
              });}}
            />
            {/* <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Status"
              styles={selectDropdownStyle}
              options={[
                {value: "Active", label: "Active"},
                {value: "For Renewal", label: "For Renewal"},
              ]}
              onChange={(e) => { setFilterConfig((prev) => {
                  return { ...prev, "renewal_status": e.value };
              });}}
            /> */}
          </div>

          <Table
            tableHeaders={[
              // "-",
              "PROJECT NAME",
              "CUSTOMER",
              "DISTRIBUTOR",
              "TOTAL AMOUNT",
              "CONTRACT DATE",
              "START DATE (DEPLOYMENT)",
              "PAYMENT STRUCTURE",
              // "STATUS", 
              "ACTIONS",
            ]}
            headerSelector={[
              // "-",
              "name",
              "customer_name",
              "distributor_name",
              "grand_total",
              "project_date",
              "start_date",
              "payment_structure",
              //  
            ]}
            tableData={allData}
            // ViewBtn={(row) => ViewBtn(row)}
            ActionBtn={(row) => ActionBtn(row, "open_bill")}
            showLoader={showLoader}
            withActionData={false}
          />
        </div>
        <div className="mb-2" />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project"
        onDelete={() => handleDeletePI()}
      />
      <AddPaymentModal
        id={projectId}
        balance={balance}
        franchiseeID={franchiseeID}
        show={showAddPaymentModal}
        onHide={handleCloseAddPaymentModal}
      />
      <ConfirmPaymentModal
        // id={projectId}
        // balance={balance}
        // franchiseeID={franchiseeID}
        show={showConfirmPaymentModal}
        onHide={handleCloseConfirmPaymentModal}
        handler={handleShowAddPaymentModal}
      />
    </div>
  );
}
