import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";

// validation
import {
  validatePettyCashOutCreate,
  validatePettyCashOutUpdate,
} from "../../Helpers/Validation/PettyCash/PettyCashValidation";
// api
import {
  postPettyCashOutDetailTransaction,
  getPettyCashOutDetailTransaction,
  updatePettyCashOutDetailTransaction,
} from "../../Helpers/apiCalls/PettyCash/PettyCashRegisterApi";
import { getAllUsers } from "../../Helpers/apiCalls/usersApi";
import { getAllExpenseType } from "../../Helpers/apiCalls/expensetypesApi";

// assets & styles
import {
  numberFormat,
  refreshPage,
  toastStyle,
  getTodayDateISO,
} from "../../Helpers/Utils/Common";
import trash from "./../../Assets/Images/trash.png";
import Navbar from "../../Components/Navbar/Navbar";
import "./PettyCash.css";
import { Fragment } from "react";
import InputError from "../../Components/InputError/InputError";
import ReactLoading from "react-loading";
import { DatePicker } from "antd";
import dayjs from "dayjs";

/**
 *  -- COMPONENT: FORM TO ADD OR EDIT PETTY CASH OUT DETAILS
 */
function PettyCashOut({ add, edit }) {
  let navigate = useNavigate();
  const [inactive, setInactive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [users, setUsers] = useState([]);
  const [expenses, setExpenses] = useState([]);

  /**
   *  @id - param for edit cash out form
   */
  const { id } = useParams();

  const [pettyCashDetails, setPettyCashDetails] = useState({
    petty_cash_id: "1",
    petty_cash_detail_id: "",
    type: "out",
    particulars: "",
    remarks: "",
    invoice_no: "",
    out_type: "",
    date: getTodayDateISO(),
    item_names: [],
    item_quantities: [],
    item_prices: [],
    item_units: [],
    amount: "0.00",
  });


  const [expenseValue, setExpenseValue] = useState({
    name: "expense_type_id",
    label: "",
    value: "",
  });

  // DATAHANDLERS

  function handleInvoiceChange(e) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      ["invoice_no"]: e.target.value,
    }));
  }

  function handleType(selectedOption) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      out_type: selectedOption.label,
    }));
    setExpenseValue(selectedOption);
  }

  function handleDateChange(e) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      ["date"]: e.target.value,
    }));
  }

  function handleParticularsChange(e) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      ["particulars"]: e.target.value,
    }));
  }

  function handleRemarksChange(e) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      ["remarks"]: e.target.value,
    }));
  }

  function handleAmountChange(amount) {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      ["amount"]: amount,
    }));
  }

  function handleItemListChange() {
    setPettyCashDetails((prevState) => ({
      ...prevState,
      item_names: orderItems.map((item) => {
        return item.item_name;
      }),
    }));
    setPettyCashDetails((prevState) => ({
      ...prevState,
      item_quantities: orderItems.map((item) => {
        return item.qty;
      }),
    }));
    setPettyCashDetails((prevState) => ({
      ...prevState,
      item_prices: orderItems.map((item) => {
        return item.price;
      }),
    }));
    setPettyCashDetails((prevState) => ({
      ...prevState,
      item_units: orderItems.map((item) => {
        return item.unit;
      }),
    }));
  }

  //ERROR HANDLING
  const [isError, setIsError] = useState({
    cash_out_date: false,
    particulars: false,
    amount: false,
    out_type: false,
    requested_by_value: false,
    list: false,
    listInfo: false,
  });

  function cleanErrorList() {
    setIsError({
      cash_out_date: false,
      particulars: false,
      amount: false,
      list: false,
      listInfo: false,
    });
  }

  /**
   *  PARTICULARS ITEM LIST
   */
  const [orderItems, setOrderItems] = useState([]);

  useEffect(handleItemListChange, [orderItems]);

  function addNewItem(
    item_name = "",
    price = "",
    unit = "",
    qty = "",
    total = "0.00"
  ) {
    const newItem = {
      qty: qty,
      unit: unit,
      item_name: item_name,
      price: price,
      total: total,
    };
    setOrderItems((oldItems) => [...oldItems, newItem]);
  }
  function deleteOrder(row) {
    const updatedOrderList = [...orderItems];
    updatedOrderList.splice(row, 1);
    setOrderItems(updatedOrderList);
    handleOrderItemsChange();
  }

  function handleOrderItemsChange(e, row, type) {
    cleanErrorList();
    setIsChanged(true);
    var temp = orderItems;
    const { name, value } = e.target;
    temp[row][name] = value;

    if (name === "qty" || name === "price") {
      var subtotal =
        temp[row].qty && temp[row].price
          ? parseFloat(
              parseInt(temp[row].qty) * parseFloat(temp[row].price)
            ).toString()
          : "0.00";
      temp[row].total = subtotal;
    }
    setOrderItems(temp);
    handleItemListChange();
    setTimeout(() => setIsChanged(false), 1);
  }


  async function handleSubmitPettyCashDetail() {
    if (validatePettyCashOutCreate(pettyCashDetails, setIsError)) {
      setIsClicked(true);
      const response = await postPettyCashOutDetailTransaction(
        pettyCashDetails
      );
      if (response) {
        if (response.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          });
          setTimeout(() => refreshPage(), 1000);
          setIsClicked(false);
        } else if (response.status === "success") {
          toast.success("Successfully created cash out transaction", {
            style: toastStyle(),
          });
          setTimeout(() => navigate("/pettycash"), 1000);
        }
      } else {
        var errMsg = response.error.data.messages.error;
        toast.error(errMsg, { style: toastStyle() });
        setIsClicked(false);
      }
    } else {
      setIsClicked(false);
      toast.error("Please fill in all fields", { style: toastStyle() });
    }
  }

  async function handleEditPettyCashDetail() {
    if (isClicked) {
      return;
    }
    if (validatePettyCashOutUpdate(pettyCashDetails, setIsError)) {
      setIsClicked(true);
      const response = await updatePettyCashOutDetailTransaction(
        pettyCashDetails
      );
      if (response) {
        if (response.status === "error") {
          toast.error(response.data.response, {
            style: toastStyle(),
          });
          setTimeout(() => refreshPage(), 1000);
          setIsClicked(false);
        } else if (response.status === "success") {
          toast.success("Successfully edited cash out transaction", {
            style: toastStyle(),
          });
          setTimeout(() => navigate("/pettycash"), 1000);
        }
      } else {
        var errMsg = response.error.data.messages.error;
        toast.error(errMsg, { style: toastStyle() });
        setIsClicked(false);
      }
    } else {
      setIsClicked(false);
      toast.error("Please fill in all fields", { style: toastStyle() });
    }
  }

  const handleSubmit = () => {
    if (isClicked) {
      return;
    }
    if (add) handleSubmitPettyCashDetail();
    else if (edit) handleEditPettyCashDetail();
  };

  /** GET API - Users **/
  async function fetchUsers() {
    setUsers([]);

    const response = await getAllUsers();

    var user_data = response.data;
    user_data.forEach(function (obj, index) {
      user_data[index].name =
        obj.first_name + " " + obj.middle_name + " " + obj.last_name;
    });

    var users = user_data.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );


    users.map((user) => {
      var info = {};

      info.name = "user_id";
      info.label = user.name;
      info.value = user.id;

      setUsers((prev) => [...prev, info]);
    });
  }

  /** GET API - ExpenseType**/

  async function fetchExpenseType() {
    setExpenses([]);

    const response = await getAllExpenseType();
    var expenses = response.data.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    expenses.map((expense) => {
      var info = {};

      info.name = "expense_type_id";
      info.label = expense.name;
      info.value = expense.id;

      setExpenses((prev) => [...prev, info]);
    });
  }

  /** FOR EDIT - Fetch Petty Cash Details */
  async function fetchPettyCashDetail() {
    const response = await getPettyCashOutDetailTransaction(id);
    if (response) {
      if (response.status === "error") {
        toast.error(response.data.response, {
          style: toastStyle(),
        });
        setTimeout(() => refreshPage(), 1000);
      } else if (response.status === "success") {
        const data = response.data["0"];
        const items = data.petty_cash_items;
        setPettyCashDetails({
          petty_cash_detail_id: data.id,
          amount: data.amount,
          remarks: data.remarks,
          invoice_no: data.invoice_no,
          type: data.type,
          out_type: data.out_type,
          requested_by_value: {
            value: data.requested_by,
            label: data.requested_by_name,
          },
          date: data.date,
          particulars: data.particulars,
          petty_cash_id: data.petty_cash_id,
          item_names: [],
          item_prices: [],
          item_units: [],
          item_quantities: [],
        });
        if (data.expenseValue) {
          setExpenseValue(data.expenseValue);
        }
        items.map((element) => {
          addNewItem(
            element.name,
            element.price,
            element.unit,
            element.qty,
            parseFloat(element.price) * parseFloat(element.qty)
          );
        });
      }
    } else {
      var errMsg = response.error;
      toast.error(errMsg, { style: toastStyle() });
    }
  }

  // FOR DYNAMIC CALCULATION
  useEffect(() => {
    var _subtotal = orderItems
      .map((item) => parseFloat(item.total))
      .reduce((a, b) => a + b, 0);

    var _grandTotal = _subtotal;
    handleAmountChange(_grandTotal.toFixed(2));
  }, [isChanged]);

  // DATA FETCHING
  useEffect(() => {
    if (edit) {
      fetchPettyCashDetail();
    }
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchExpenseType();
  }, []);


  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"PETTY CASH"}
        />
      </div>

      <div className={`container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center pb-4">
          <h1 className="page-title mb-0">
            {add
              ? "ADD PETTY CASH OUT TRANSACTION"
              : "EDIT PETTY CASH OUT TRANSACTION"}
          </h1>
        </div>

        {/* content */}
        <div className="edit-form">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mt-4 mb-2">
              <Col xs={4}>
                <span className="edit-label">
                  Cash Out Date
                  <span className="color-red"> *</span>
                </span>
              </Col>
              <Col xs={4}>
                <span className="edit-label">Invoice No.</span>
              </Col>
              <Col xs={4}>
                <span className="edit-label">
                  Account Type
                  <span className="color-red"> *</span>
                </span>
              </Col>
            </Row>
            <Row>
            <Col xs={4}>
              <DatePicker
                className="nc-modal-custom-text-new-datepicker"
                name="cashout_date"
                value={pettyCashDetails.date && pettyCashDetails.date !== "0000-00-00" ? dayjs(pettyCashDetails.date) : null} // Ensure valid date or empty
                format="YYYY-MM-DD"  // Adjust this date format if needed
                onChange={(date, dateString) => handleDateChange({ target: { name: "cashout_date", value: dateString } })}
              />
            </Col>
              <Col xs={4}>
                <Form.Control
                  type="text"
                  name="cashout_invoice"
                  className="nc-modal-custom-text"
                  defaultValue={""}
                  value={pettyCashDetails.invoice_no}
                  onChange={(e) => handleInvoiceChange(e)}
                />
              </Col>
              <Col xs={4}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Expense Type..."
                  value={expenseValue}
                  options={expenses}
                  onChange={(e) => handleType(e)}
                />
                <InputError
                  isValid={isError.out_type}
                  message={"Type is required"}
                />
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">PARTICULARS</span>
                <span className="color-red"> *</span>
              </Col>
              <Col>
                <span className="edit-label">REQUESTED BY</span>
                <span className="color-red"> *</span>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <Form.Control
                  type="text"
                  name="cashout_particulars"
                  className="nc-modal-custom-text"
                  defaultValue={""}
                  value={pettyCashDetails.particulars}
                  onChange={(e) => handleParticularsChange(e)}
                />
              </Col>
              <Col>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  value={pettyCashDetails.requested_by_value}
                  options={users}
                  onChange={(e) => {
                    setPettyCashDetails((prevState) => ({
                      ...prevState,
                      ["requested_by"]: e.value,
                      ["requested_by_value"]: {
                        value: e.value,
                        label: e.label,
                      },
                    }));
                  }}
                />
                <InputError
                  isValid={isError.requested_by}
                  message={"Requested is required"}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">REMARKS</span>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <Form.Control
                  type="text"
                  name="cashout_remarks"
                  className="nc-modal-custom-text"
                  defaultValue={""}
                  value={pettyCashDetails.remarks}
                  onChange={(e) => handleRemarksChange(e)}
                />
              </Col>
            </Row>
          </Fragment>

          {/* ORDER ITEMS */}
          <Row className="mt-4 pt-3">
            <Col>
              <span className="edit-label mb-2">
                List Particulars
                <span className="color-red"> *</span>
              </span>
              <div className="edit-purchased-items">
                {orderItems.length !== 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">item</th>
                        <th className="color-gray">quantity</th>
                        <th className="color-gray">unit</th>
                        <th className="color-gray">price</th>
                        <th className="color-gray">total</th>
                        <th className="color-gray">actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="item_name"
                                defaultValue={item.item_name}
                                onChange={(e) =>
                                  handleOrderItemsChange(e, index)
                                }
                              />
                              <InputError
                                isValid={isError.listInfo}
                                message={"Item is required"}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="qty"
                                defaultValue={item.qty}
                                onChange={(e) =>
                                  handleOrderItemsChange(e, index)
                                }
                              />
                              <InputError
                                isValid={isError.listInfo}
                                message={"Quantity is required"}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="unit"
                                defaultValue={item.unit}
                                onChange={(e) =>
                                  handleOrderItemsChange(e, index)
                                }
                              />

                              <InputError
                                isValid={isError.listInfo}
                                message={"Unit is required"}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="price"
                                value={item.price}
                                onChange={(e) =>
                                  handleOrderItemsChange(e, index)
                                }
                              />

                              <InputError
                                isValid={isError.listInfo}
                                message={"Price is required"}
                              />
                            </td>
                            <td className="color-green">
                              {numberFormat(item.total)}
                              {item.exceed ? <p></p> : ""}
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => deleteOrder(index)}
                                className="cursor-pointer"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className="entries-not-found">
                    There is no ordered items yet.
                  </div>
                )}
              </div>
              <InputError
                isValid={isError.list}
                message={"Please add an item"}
              />
            </Col>
          </Row>

          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" disabled={false} onClick={() => addNewItem()}>
              Add Item
            </Button>
          </Row>

          {/* GRAND TOTAL */}
          <Fragment>
            <Row className="align-right py-5">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Grand Total
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  {numberFormat(pettyCashDetails.amount)}
                </span>
              </Col>
            </Row>
          </Fragment>

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <button
                type="button"
                className="button-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PettyCashOut.defaultProps = {
  add: false,
  edit: false,
};

export default PettyCashOut;
