import React, { useState, useEffect } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "../../Components/TableTemplate/DataTable";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Select from "react-select";
import {
  getType,
  numberFormat,
  getTodayDateISO,
  selectDropdownStyle,
  TokenExpiry,
  toastStyle,
  formatDateNoTime,
} from "../../Helpers/Utils/Common";
import { CSVLink } from "react-csv";
import downloadIcon from "../../Assets/Images/download_icon.png";
import "./ProjectExpenses.css";
import "../Purchases/PurchaseOrders/PurchaseOrders.css";
import { searchProject } from "./../../Helpers/apiCalls/Manage/Projects";
import { searchPartner } from "../../Helpers/apiCalls/Manage/PartnerAPI";
import { getAllSuppliers } from "../../Helpers/apiCalls/suppliersApi";
import {
  searchProjectExpense,
  deleteProjectExpense,
} from "./../../Helpers/apiCalls/ProjectInvoice/ProjectExpenseApi";
import { getDistributor } from "../../Helpers/apiCalls/Distributor/DistributorApi";

export default function ProjectExpenses() {
  let navigate = useNavigate();
  const userType = getType();
  const [inactive, setInactive] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [projectId, setProjectId] = useState("");

  const excelHeaders = [
    { label: "DATE", key: "project_expense_date" },
    { label: "PROJECT NAME", key: "name" },
    { label: "PARTNER", key: "partner_name" },
    { label: "AMOUNT", key: "amount" },
    { label: "REMARKS", key: "remarks" },
    { label: "ADDED BY", key: "added_by_name" },
  ];

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    status: "pending",
    distributor_id: "",
    // franchise: "",
    // tabKey: "",
    // project_id: "",
    // franchised_on_from: "",
    // franchised_on_to: "",
  });

  const handleTabSelect = (tabKey) => {
    var newFilterConfig = {
      tab: tabKey,
    };

    switch (tabKey) {
      case "pending":
        setTableData([]);
        newFilterConfig.status = "pending";
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "approved":
        setTableData([]);
        newFilterConfig.status = "approved";
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "disapproved":
        setTableData([]);
        newFilterConfig.status = "disapproved";
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      case "all":
        setTableData([]);
        // newFilterConfig.status = "";
        setFilterConfig(() => {
          return newFilterConfig;
        });
        break;
      default:
        setTableData([]);
        break;
    }
  };

  async function fetchDistributors() {
    setAllDistributors([]);
    const response = await getDistributor();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{value: '', label: 'All Distributors'}, ...res]);
    }
  }

  async function fetchProjects() {
    setShowLoader(true);
    setProjects([]);

    const response = await searchProject({});
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var projects = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setProjects([{ label: "All Projects", value: "" }, ...projects]);
    }

    setShowLoader(false);
  }

  async function fetchPartners() {
    setShowLoader(true);
    setPartners([]);

    const response = await searchPartner(null);
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    } else {
      var partners = response.data.data.map((data) => {
        var info = {};
        info.label = data.name;
        info.value = data.id;
        return info;
      });
      setPartners([{ label: "All Partners", value: "" }, ...partners]);
    }

    setShowLoader(false);
  }

  async function fetchAllSuppliers() {
    setSuppliers([]);
    const response = await getAllSuppliers();
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.trade_name,
        };
      });
      setSuppliers(res);
    }
  }

  async function fetchData() {
    setShowLoader(true);
    setTableData([]);
    const response = await searchProjectExpense(filterConfig);
    if (response.data) {
      var allData = response.data.data.map((data) => {
        var info = data;
        info.added_on =
          data.added_on !== "0000-00-00" ? formatDateNoTime(data.added_on) : "";
        info.amount = numberFormat(data.amount);
        info.other_fees = numberFormat(data.other_fees);
        info.grand_total = numberFormat(data.grand_total);
        info.balance = numberFormat(data.balance);
        info.paid_amount = data.paid_amount
          ? numberFormat(data.paid_amount)
          : "0.00";
        return info;
      });
      setTableData(allData);
    } else if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error);
      }
    }
    setShowLoader(false);
  }

  async function handleDeletePI() {
    const response = await deleteProjectExpense(projectId);

    if (response.data) {
      toast.success("Project Expense Deleted Successfully!", {
        style: toastStyle(),
      });
      setShowDeleteModal(false);
      fetchData();
      // setTimeout(() => refreshPage(), 1000);
    } else {
      toast.error("Error Deleting Project Expense", {
        style: toastStyle(),
      });
    }
  }

  // function handleSelectChange(e, id, franchisee_id, balance) {
  function handleSelectChange(e, row) {
    if (e.target.value === "edit-pi") {
      navigate("edit/" + row.id);
    } else if (e.target.value === "print-pi") {
      navigate("print/" + row.id);
    } else if (e.target.value === "delete-pi") {
      setProjectId(row.id);
      handleShowDeleteModal();
    } else if (e.target.value === "view") {
      navigate("view/" + row.id + "/" + row.status);
    }
  }

  function ActionBtn(row, type) {
    return (
      <Form.Select
        name="action"
        className="PO-select-action"
        onChange={(e) =>
          // handleSelectChange(e, row.project_id, row.id, row.balance)
          handleSelectChange(e, row)
        }
      >
        <option value="" hidden selected>
          Select
        </option>
        {userType === "admin" && row.status !== "approved" ? (
          <option value="edit-pi" className="color-options">
            Edit
          </option>
        ) : null}
        {userType === "admin" && (
          <option value="delete-pi" className="color-red">
            Delete
          </option>
        )}
        <option value="view">View</option>
      </Form.Select>
    );
  }

  // function ViewBtn(row) {
  //   return (
  //     <button
  //       type="button"
  //       className="btn btn-sm view-btn-table"
  //       onClick={(e) => navigate("view/" + row.id + "/" + row.status)}
  //     >
  //       View
  //     </button>
  //   );
  // }

  useEffect(() => {
    fetchProjects();
    fetchPartners();
    fetchAllSuppliers();
    fetchDistributors();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterConfig]);

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
          active={"EXPENSE"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title">PROJECT EXPENSE</h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button
              className="add-btn"
              onClick={() => navigate("/projectexpense/add")}
            >
              Add
            </button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end mb-3">
            <div className="justify-content-center align-items-center ">
              <CSVLink
                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                data={tableData}
                headers={excelHeaders}
                target="_blank"
                filename={`${getTodayDateISO()} Project Expense`}
              >
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                Download Excel
              </CSVLink>
            </div>
          </Col>
        </Row>

        <Tabs
          activeKey={filterConfig.status}
          defaultActiveKey={filterConfig.status}
          id="PO-tabs"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="pending" title="FOR APPROVAL">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">FILTER BY:</span>

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="All Projects"
                styles={selectDropdownStyle}
                // value={branch}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => (
                    { ...prev, project_id: e.value }
                  ));
                }}
              />

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Distributor"
                styles={selectDropdownStyle}
                options={allDistributors}
                onChange={(e) => {
                  setFilterConfig((prev) => (
                    { ...prev, distributor_id: e.value }
                  ));
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Supplier"
                styles={selectDropdownStyle}
                options={suppliers}
                onChange={(e) => {
                  setFilterConfig((prev) => (
                    { ...prev, supplier_id: e.value }
                  ));
                }}
              />
            </div>
            {/* content */}
            <div className="">
              <Table
                tableHeaders={[
                  // "-",
                  "DATE",
                  "PROJECT NAME",
                  "DISTRIBUTOR",
                  "AMOUNT",
                  "OTHER FEES",
                  "GRAND TOTAL",
                  "REMARKS",
                  "ADDED BY",
                  "ACTIONS",
                ]}
                headerSelector={[
                  // "-",
                  "project_expense_date",
                  "name",
                  "distributor_name",
                  "amount",
                  "other_fees",
                  "grand_total",
                  "remarks",
                  "added_by_name",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row, "pending")}
                showLoader={showLoader}
                withActionData={false}
              />
            </div>
            <div className="mb-2" />
          </Tab>
          <Tab eventKey="approved" title="APPROVED">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">FILTER BY:</span>

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="All Projects"
                styles={selectDropdownStyle}
                // value={branch}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Distributor"
                styles={selectDropdownStyle}
                options={allDistributors}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, distributor_id: e.value };
                  });
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Supplier"
                styles={selectDropdownStyle}
                options={suppliers}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, supplier_id: e.value };
                  });
                }}
              />
            </div>

            {/* content */}
            <div className="">
              <Table
                tableHeaders={[
                  // "-",
                  "DATE",
                  "PROJECT NAME",
                  "DISTRIBUTOR",
                  "AMOUNT",
                  "OTHER FEES",
                  "GRAND TOTAL",
                  "REMARKS",
                  "ADDED BY",
                  "ACTIONS",
                ]}
                headerSelector={[
                  // "-",
                  "project_expense_date",
                  "name",
                  "distributor_name",
                  "amount",
                  "other_fees",
                  "grand_total",
                  "remarks",
                  "added_by_name",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row, "approved")}
                showLoader={showLoader}
                withActionData={false}
              />
            </div>
            <div className="mb-2" />
          </Tab>
          <Tab eventKey="disapproved" title="DISAPPROVED">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">FILTER BY:</span>

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="All Projects"
                styles={selectDropdownStyle}
                // value={branch}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Distributor"
                styles={selectDropdownStyle}
                options={allDistributors}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, distributor_id: e.value };
                  });
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Supplier"
                styles={selectDropdownStyle}
                options={suppliers}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, supplier_id: e.value };
                  });
                }}
              />
            </div>

            {/* content */}
            <div className="">
              <Table
                tableHeaders={[
                  // "-",
                  "DATE",
                  "PROJECT NAME",
                  "DISTRIBUTOR",
                  "AMOUNT",
                  "OTHER FESS",
                  "GRAND TOTAL",
                  "REMARKS",
                  "ADDED BY",
                  "ACTIONS",
                ]}
                headerSelector={[
                  // "-",
                  "project_expense_date",
                  "name",
                  "distributor_name",
                  "amount",
                  "other_fees",
                  "grand_total",
                  "remarks",
                  "added_by_name",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row, "disapproved")}
                showLoader={showLoader}
                withActionData={false}
              />
            </div>
            <div className="mb-2" />
          </Tab>
          <Tab eventKey="all" title="ALL">
            {/* filters */}
            <div className="my-2 ms-2 PO-filters PI-filters d-flex">
              <span className="me-3 align-middle mt-2">FILTER BY:</span>

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="All Projects"
                styles={selectDropdownStyle}
                options={projects}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, project_id: e.value };
                  });
                }}
              />

              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Distributor"
                styles={selectDropdownStyle}
                options={allDistributors}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, distributor_id: e.value };
                  });
                }}
              />
              <Select
                className="dropsearch-filter px-0 py-0 me-2"
                classNamePrefix="react-select"
                placeholder="Select Supplier"
                styles={selectDropdownStyle}
                options={suppliers}
                onChange={(e) => {
                  setFilterConfig((prev) => {
                    return { ...prev, supplier_id: e.value };
                  });
                }}
              />
            </div>

            {/* content */}
            <div className="">
              <Table
                tableHeaders={[
                  // "-",
                  "DATE",
                  "PROJECT NAME",
                  "DISTRIBUTOR",
                  "AMOUNT",
                  "OTHER FEES",
                  "GRAND TOTAL",
                  "REMARKS",
                  "ADDED BY",
                  "ACTIONS",
                ]}
                headerSelector={[
                  // "-",
                  "project_expense_date",
                  "name",
                  "distributor_name",
                  "amount",
                  "other_fees",
                  "grand_total",
                  "remarks",
                  "added_by_name",
                ]}
                tableData={tableData}
                // ViewBtn={(row) => ViewBtn(row)}
                ActionBtn={(row) => ActionBtn(row, "")}
                showLoader={showLoader}
                withActionData={false}
              />
            </div>
            <div className="mb-2" />
          </Tab>
        </Tabs>
        <div className="mb-2" />
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project expense"
        onDelete={() => handleDeletePI()}
      />
    </div>
  );
}
