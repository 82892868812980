// import { handleValidationChange } from "../CommonValidation";
import {handleValidationChange} from "../CommonValidation"

export const validateProject = (data, recurring, oneTimeFees, setIsError) => {
  var isValid = true;

  // if (recurring.length !== 0) {
  //       recurring.map((data) => {
  //           if (
  //               data.prices === "" ||
  //               data.prices === undefined ||
  //               data.prices === 0 ||
  //               data.prices === "0"
  //           ) {
  //               handleValidationChange("prices", true, setIsError);
  //               isValid = false;
  //           } else {
  //               handleValidationChange("prices", false, setIsError);
  //           }
  //       });
  //   } else {
  //       handleValidationChange("received_items_table", true, setIsError);
  //       isValid = false;
  //   }

  const filteredRecurring =  recurring.filter((child) => {
    return (
      Object.keys(child).length > 0 &&
      child.description &&
      child.description.trim() !== "" &&
      child.type &&
      child.type !== "" &&
      child.periods &&
      child.periods !== "" &&
      child.prices &&
      child.prices !== ""
    );
  });

  const filteredOneTimeFees = Object.values(oneTimeFees).filter((child) => {
    return (
      Object.keys(child).length > 0 &&
      child.description &&
      child.description.trim() !== "" &&
      child.amount &&
      child.amount !== "" 
    );
  });

      if (filteredRecurring.length===0 && filteredOneTimeFees.length===0) {
        handleValidationChange("received_items_table", true, setIsError);
        isValid = false;
      } else {
        handleValidationChange("received_items_table", false, setIsError);
      }


    if (data.name === "") {
      handleValidationChange("name", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("name", false, setIsError);
    }

    if (data.project_date === "") {
      handleValidationChange("project_date", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("project_date", false, setIsError);
    }

    if (data.start_date === "") {
      handleValidationChange("start_date", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("start_date", false, setIsError);
    }

    if (data.customer_id === "") {
      handleValidationChange("customer_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("customer_id", false, setIsError);
    }

    // if (data.contact_person === "") {
    //   handleValidationChange("contact_person", true, setIsError);
    //   isValid = false;
    // } else {
    //   handleValidationChange("contact_person", false, setIsError);
    // }

    // if (data.contact_number === "") {
    //   handleValidationChange("contact_number", true, setIsError);
    //   isValid = false;
    // } else {
    //   handleValidationChange("contact_number", false, setIsError);
    // }

    // if (data.address === "") {
    //   handleValidationChange("address", true, setIsError);
    //   isValid = false;
    // } else {
    //   handleValidationChange("address", false, setIsError);
    // }

    if (data.project_type === "") {
      handleValidationChange("project_type", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("project_type", false, setIsError);
    }

    return isValid;
  };


export const validateProjectInvoice = (data, particulars, setIsError) => {

  var isValid = true;

  if (data.project_id === "" || data.project_id === undefined) {
    handleValidationChange("project_id", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("project_id", false, setIsError);
  }
  
  if (data.project_date === "" || data.project_date === undefined) {
    handleValidationChange("project_date", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("project_date", false, setIsError);
  }
  
  if (data.invoice_date === "" || data.invoice_date === undefined) {
    handleValidationChange("invoice_date", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("invoice_date", false, setIsError);
  }

  // if (data.address === "" || data.address === undefined) {
  //   handleValidationChange("address", true, setIsError);
  //   isValid = false;
  // } else {
  //   handleValidationChange("address", false, setIsError);
  // }
  
  if (data.company === "" || data.company === undefined) {
    handleValidationChange("company", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("company", false, setIsError);
  }

  if (particulars.length !== 0) {
    handleValidationChange("items", false, setIsError);
      particulars.map((data) => {
        if (
            data.qty === "" ||
            data.qty === undefined ||
            data.qty === 0
        ) {
            handleValidationChange("item_info", true, setIsError);
            isValid = false;
        } else {
            handleValidationChange("item_info", false, setIsError);
        }
        if (
            data.price === "" ||
            data.price === undefined ||
            data.price === 0
        ) {
            handleValidationChange("item_info", true, setIsError);
            isValid = false;
        } else {
            handleValidationChange("item_info", false, setIsError);
        }
    });
    } else {
        handleValidationChange("items", true, setIsError);
        isValid = false;
    }
  return isValid;
};

export const validateProjectExpense = (data, setIsError) => {

  var isValid = true;

  if (data.project_id === "" || data.project_id === undefined || data.project_id === null) {
    handleValidationChange("project_id", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("project_id", false, setIsError);
  }

  if (data.project_expense_date === "" || data.project_expense_date === undefined || data.project_expense_date === null) {
    handleValidationChange("project_expense_date", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("project_expense_date", false, setIsError);
  }

  if (data.project_price === "" || data.project_price === undefined || data.project_price === null) {
    handleValidationChange("project_price", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("project_price", false, setIsError);
  }

  if (data.expense_type_id === "" || data.expense_type_id === undefined || data.expense_type_id === null) {
    handleValidationChange("expense_type_id", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("expense_type_id", false, setIsError);
  }

  if (data.amount === "" || data.amount === undefined || data.amount === null) {
    handleValidationChange("amount", true, setIsError);
    isValid = false;
  } else {
    handleValidationChange("amount", false, setIsError);
  }

  return isValid;
};
