import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { toastStyle } from "./Helpers/Utils/Common";
import { getToken, getType, refreshPage } from "./Helpers/Utils/Common";
import axios from "axios";
import toast from "react-hot-toast";

import "./Components/FontAwesomeIcons";
import "./Assets/Images/FontAwesomeIcons";
import "./App.css";

/** PAGES **/

/**
 * -- LOGIN SCREEN
 */
import Login from "./Pages/Login/Login";

/**
 * -- DASHBOARD
 */
import Dashboard3 from "./Pages/Dashboard/Dashboard3";

/**
 * -- FRANCHISE
 */
import Franchise from "./Pages/Franchise/Franchise";

// Franchise Sales Invoice
import FormFranchiseInvoice from "./Pages/Franchise/FormFranchiseInvoice";
import SuppliesExpenses from "./Pages/Expenses/SuppliesExpenses/SuppliesExpenses";
import FormSuppliesExpenses from "./Pages/Expenses/SuppliesExpenses/Components/FormSuppliesExpenses";
import ReviewSuppliesExpense from "./Pages/Expenses/SuppliesExpenses/Components/ReviewSuppliesExpense";
import FormPaySupplier from "./Pages/Purchases/Pay Suppliers/Components/FormPaySupplier";
import PrintSuppliesExpense from "./Pages/Expenses/SuppliesExpenses/Components/PrintSuppliesExpense";
import SalesInvoice from "./Pages/Sales/SalesInvoice";
import FormSalesInvoice from "./Pages/Sales/FormSalesInvoice";

// Franchise Sales Invoice
import FranchiseSalesReport from "./Pages/Franchise/FranchiseSalesReport";

// Monthly Sales Billing
import FranchiseBilling from "./Pages/Franchise/FranchiseBilling/FranchiseBilling";
import FranchiseBillingForm from "./Pages/Franchise/FranchiseBilling/FranchiseBillingForm";
import FranchiseBillingView from "./Pages/Franchise/FranchiseBilling/FranchiseBillingView";

// Receivables
import Receivables from "./Pages/Franchise/Receivables/Receivables";
import PrintReceivable from "./Pages/Franchise/Receivables/PrintReceivable";
import PrintSalesInvoice from "./Pages/Sales/PrintSalesInvoice";
import PrintFranchiseInvoice from "./Pages/Franchise/PrintFranchiseInvoice";

// Franchisee Payments
import Payments from "./Pages/Franchise/Payments/Payments";
import PaymentsPrint from "./Pages/Franchise/Payments/PrintPayments";

/**
 * -- PURCHASES
 */

// Purchase Orders
import PurchaseOrders from "./Pages/Purchases/PurchaseOrders/PurchaseOrders";
import AddPurchaseOrder from "./Pages/Purchases/PurchaseOrders/Components/AddPurchaseOrder";
import EditPurchaseOrder from "./Pages/Purchases/PurchaseOrders/Components/EditPurchaseOrder";
import PrintPurchaseOrder from "./Pages/Purchases/PurchaseOrders/Components/PrintPurchaseOrder";
import ReviewPurchaseOrder from "./Pages/Purchases/PurchaseOrders/Components/ReviewPurchaseOrder";

// Purchase Invoice
import PurchaseInvoices from "./Pages/Purchases/Purchase Invoice/PurchaseInvoices";
import FormPurchaseInvoice from "./Pages/Purchases/Purchase Invoice/Components/FormPurchaseInvoice";
import PrintPurchaseInvoice from "./Pages/Purchases/Purchase Invoice/Components/PrintPurchaseInvoice";
import PayPurchaseInvoice from "./Pages/Purchases/Purchase Invoice/Components/PayPurchaseInvoice";

// Pay Supplier
import ViewPaySupplier from "./Pages/Purchases/Pay Suppliers/Components/ViewPaySupplier";
import PaySuppliers from "./Pages/Purchases/Pay Suppliers/PaySuppliers";
import ViewInvoice from "./Pages/Purchases/Pay Suppliers/Components/ViewInvoice";

/**
 * -- SUPPLIES
 */

// Purchase Orders
import PO from "./Pages/Expenses/PurchaseOrders/PurchaseOrders";
import AddPO from "./Pages/Expenses/PurchaseOrders/Components/AddPurchaseOrder";
import EditPO from "./Pages/Expenses/PurchaseOrders/Components/EditPurchaseOrder";
import PrintPO from "./Pages/Expenses/PurchaseOrders/Components/PrintPurchaseOrder";
import ReviewPO from "./Pages/Expenses/PurchaseOrders/Components/ReviewPurchaseOrder";
import SEFormPurchaseInvoice from "./Pages/Expenses/Purchase Invoice/Components/SEFormPurchaseInvoice";

// Purchase Invoice
import PrintPI from "./Pages/Expenses/Purchase Invoice/Components/PrintPurchaseInvoice";
import PayPI from "./Pages/Expenses/Purchase Invoice/Components/PayPurchaseInvoice";
import PI from "./Pages/Expenses/Purchase Invoice/PurchaseInvoices";

// Pay Suppliers
import ViewPaySupplierE from "./Pages/Expenses/Pay Suppliers/Components/ViewPaySupplier";
import PaySuppliersE from "./Pages/Expenses/Pay Suppliers/PaySuppliers";
import FormPaySupplierE from "./Pages/Expenses/Pay Suppliers/Components/FormPaySupplier";
import ViewInvoiceSe from "./Pages/Expenses/Pay Suppliers/Components/ViewInvoice";

/**
 * -- INVENTORY
 */
import ItemLists from "./Pages/Inventory/ItemLists";
import LowOnStock from "./Pages/Inventory/LowOnStock";
import BranchAdjustment from "./Pages/Inventory/ItemList/BranchAdjustment";
import ItemHistory from "./Pages/Inventory/ItemList/ItemHistory";
import ItemHistorySearch from "./Pages/Inventory/ItemList/ItemHistorySearch";
import ItemDetails2 from "./Pages/Inventory/ItemList/ItemDetails2";
import Requests from "./Pages/Inventory/Requests";
import ViewRequests from "./Pages/Inventory/Requests/ViewRequest";
import Transfers from "./Pages/Inventory/Transfers";
import IncomingTransfers from "./Pages/Inventory/IncomingTransfers";
import ViewTransfer from "./Pages/Inventory/ViewTransfer";
import ReviewTransfer from "./Pages/Inventory/ReviewTransfer";
import FormTransfer from "./Pages/Inventory/FormTransfer";
import FormReceiveTransfer from "./Pages/Inventory/FormReceiveTransfer";
import ViewTransferReceive from "./Pages/Inventory/ViewTransferReceive";
import BranchInventory from "./Pages/Inventory/BranchInventory";
// import ItemHistoryFilter from "./Pages/Inventory/ItemHistory";
import Adjustments from "./Pages/Inventory/Adjustments";
import StoreAdjustments from "./Pages/Inventory/StoreAdjustments";
import ViewAdjustment from "./Pages/Inventory/Adjustment/ViewAdjustment";

/**
 * -- REPORTS
 */
import CustomerPaymentsReports from "./Pages/Reports/CustomerPaymentReports/CustomerPaymentReports";
import DailyAttendanceList from "./Pages/Reports/DailyAttendanceList/DailyAttendanceList";
import DailyCashCounts from "./Pages/Reports/DailyCashCounts/DailyCashCounts";
import DailyExpenses from "./Pages/Reports/DailyExpenses/DailyExpenses";
import DailyWastage from "./Pages/Reports/DailyWastage/DailyWastage";
import DailySales from "./Pages/Reports/DailySales/DailySales";
import DailyOrders from "./Pages/Reports/DailyOrders/DailyOrders";
import PrintDailySales from "./Pages/Reports/DailySales/PrintDailySales";
import WastageReports from "./Pages/Reports/WastageReports/WastageReports";

/**
 * -- REPORTS VIEw
 */
import ViewDailyCashCount from "./Pages/Reports/DailyCashCounts/ViewDailyCashCount";
import ViewDailyExpenses from "./Pages/Reports/DailyExpenses/ViewDailyExpenses";
import PrintDailyExpenses from "./Pages/Reports/DailyExpenses/PrintDailyExpenses";
import ViewDailyWastage from "./Pages/Reports/DailyWastage/ViewDailyWastage";
import ViewDailyAttendanceList from "./Pages/Reports/DailyAttendanceList/ViewDailyAttendanceList";
import EmployeeHours from "./Pages/Reports/DailyAttendanceList/EmployeeHours";
import BranchDailyOrders from "./Pages/Reports/DailyOrders/BranchDailyOrders";
import StoreDeposit from "./Pages/Reports/StoreDeposit/StoreDeposit";
import ViewDailySales from "./Pages/Reports/DailySales/ViewDailySales";

/**
 * -- OFFICE REPORTS
 */
import Payables from "./Pages/OfficeReports/Payables";
import TotalSales from "./Pages/OfficeReports/TotalSales/TotalSales";
import FranchiseeSalesReport from "./Pages/OfficeReports/FranchiseeSalesReport/FranchiseeSalesReport";
import SalesByItem from "./Pages/OfficeReports/SalesByItem/SalesByItem";
import ListOfStores from "./Pages/OfficeReports/Stores";
import ListOfBranches from "./Pages/Reports/BranchList/BranchList";
import PurchasedItemsSummary from "./Pages/OfficeReports/PurchasedItemsSummary/PurchasedItemsSummary";
import TransferHistory from "./Pages/OfficeReports/TransferHistory/TransferHistory";
import ViewTransferHistory from "./Pages/OfficeReports/TransferHistory/ViewTransferHistory";
import ProductionReport from "./Pages/OfficeReports/ProductionReport/ProductionReport";
import IncomeStatementReport from "./Pages/FinancialReport/IncomeStatementReport";

/**
 * -- MANAGE
 */
import Distributor from "./Pages/Distributors/Distributor";
import DistributorForm from "./Pages/Distributors/DistributorForm";
import DistributorBillings from "./Pages/Distributors/DistributorBillings";
import SubscriptionBillings from "./Pages/Subscription/SubscriptionBilling";
import GenerateSubscriptionBilling from "./Pages/Subscription/GenerateSubscriptionBilling";
// Suppliers
import Suppliers from "./Pages/Manage/Suppliers";

// Vendors
import Vendors from "./Pages/Manage/Vendors";

// Banks
import Banks from "./Pages/Manage/Banks";

// Branches
import Branches from "./Pages/Manage/Branches";
import Warehouses from "./Pages/Manage/Warehouses";

// Branch Group
import BranchGroup from "./Pages/Manage/BranchGroup";

// Inventory Level
import InventoryLevel from "./Pages/Manage/InventoryLevel";
import FormInventoryLevel from "./Pages/Manage/InventoryLevel/FormInventoryLevel";

// Items
import Items from "./Pages/Manage/Items";

// Products
import Products from "./Pages/Manage/Products";

// Products
import ItemPrices from "./Pages/Manage/ItemPrices/ItemPrices";

//DSR Items
import DsrItems from "./Pages/Manage/DsrItems/DsrItems";

// Price Levels
import PriceLevels from "./Pages/Manage/PriceLevels";
import AddPriceLevel from "./Pages/Manage/Components/AddPL";
import EditPriceLevel from "./Pages/Manage/Components/EditPL";
import ViewPriceLevel from "./Pages/Manage/Components/ViewPL";

// Users
import Users from "./Pages/Manage/Users";

// Customer
import Customer from "./Pages/Manage/Customer";

// Forwarders
import Forwarders from "./Pages/Manage/Forwarders";

// ExpenseTypes
import ExpenseTypes from "./Pages/Manage/ExpenseTypes";

// BuildItem
import BuildItem from "./Pages/Manage/BuildItem";
import BuildItemView from "./Pages/Manage/BuildItemView";

// PettyCash
import PettyCash from "./Pages/PettyCash/PettyCash";
import PettyCashCashInForm from "./Pages/PettyCash/PettyCashCashInForm";
import PettyCashCashInView from "./Pages/PettyCash/PettyCashCashInView";
import PettyCashCashOutForm from "./Pages/PettyCash/PettyCashCashOutForm";
import PettyCashCashOutView from "./Pages/PettyCash/PettyCashCashOutView";

import ProjectInvoice from "./Pages/ProjectInvoice/ProjectInvoice";
import PrintProjectInvoice from "./Pages/ProjectInvoice/PrintProjectInvoice";
import FormProjectInvoice from "./Pages/ProjectInvoice/FormProjectInvoice";
import Project from "./Pages/Project/Project";
import FormProject from "./Pages/Project/FormProject";
import PrintProject from "./Pages/Project/PrintProject";
import ReceivablesAgingReport from "./Pages/SalesReport/ReceivablesAgingReport/ReceivablesAgingReport";
import PayablesAgingReport from "./Pages/ExpensesReport/PayablesAgingReport/PayablesAgingReport";
import ExpenseByType from "./Pages/ExpensesReport/ExpenseByType/ExpenseByType";
import ExpenseByDate from "./Pages/ExpensesReport/ExpenseByDate/ExpenseByDate";
import ExpenseReport from "./Pages/ExpensesReport/ExpenseReport/ExpenseReport";
import ProjectSales from "./Pages/Project/ProjectSales";
import ProjectExpenses from "./Pages/ProjectExpenses/ProjectExpenses";
import FormProjectExpenses from "./Pages/ProjectExpenses/FormProjectExpenses";
import ViewProjectExpenses from "./Pages/ProjectExpenses/ViewProjectExpenses";
import PrintReceivablesAgingReport from "./Pages/SalesReport/ReceivablesAgingReport/PrintReceivablesAgingReport";
import PrintDistributor from "./Pages/Distributors/PrintDistributor";
import DistributorGenerateBilling from "./Pages/Distributors/DistributorGenerateBilling";
import ViewDistributorBilling from "./Pages/Distributors/ViewDistributorBilling";
import ViewSubscriptionBilling from "./Pages/Subscription/ViewSubscriptionBilling";
import MonthlyReport from "./Pages/Reports/MonthlyReport/MonthlyReport";

function App() {
  document.title = "MYT";
  document.body.style = "background: #F8F7F7;";

  const token = getToken();
  const type = getType();

  // React.useEffect(() => {
  //   // Set up a global response interceptor
  //   axios.interceptors.response.use(
  //     (response) => {
  //       return response;
  //     },
  //     async (error) => {
  //       // Handle 401 error
  //       if (error.response && error.response.status === 401) {
  //         localStorage.clear();
  //         toast.error(
  //           "Unauthorized. Logging you out...",
  //           { autoClose: 5000 },
  //           { style: toastStyle() }
  //         );
  //         setTimeout(() => {
  //           refreshPage();
  //           window.location.href = "/";
  //         }, 1000);
  //       }

  //       // Propagate the error to the calling code
  //       return Promise.reject(error);
  //     }
  //   );
  // }, []);

  return (
    <div className="page">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ style: { toastStyle } }}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={token ? <Dashboard3 /> : <Login />}
          />
          <Route
            path="/franchise"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <Franchise />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchise/add"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormFranchiseInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchise/edit/:pi_id"
            element={
              token && type === "admin" ? (
                <FormFranchiseInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchise/print/:franchisee_id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PrintFranchiseInvoice />
              ) : (
                <Login />
              )
            }
          />
          {/* FRANCHISE - Franchise Sales Invoice */}
          <Route
            path="/salesinvoice"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <SalesInvoice />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesinvoice/add"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormSalesInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesinvoice/edit/:franchisee_sale_id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormSalesInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesinvoice/process/:franchisee_sale_id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormSalesInvoice process />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesinvoice/createinvoice/:franchisee_sale_id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormSalesInvoice createinvoice />
              ) : (
                // <FormCreateSalesInvoice edit />
                <Login />
              )
            }
          />
          <Route
            path="/salesinvoice/print/:franchisee_sale_id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PrintSalesInvoice />
              ) : (
                <Navigate to="/salesinvoice" />
              )
            }
          />
          {/* PROJECT - PROJECT Register */}
          <Route
            path="/project"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <Project />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/project/add"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProject add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/project/edit/:id"
            element={
              token && type === "admin" ? (
                <FormProject edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/project/renew/:id"
            element={
              token && type === "admin" ? (
                <FormProject renew />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/project/print/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PrintProject />
              ) : (
                <Login />
              )
            }
          />
          {/* PROJECTS - Project Sales Invoice */}
          <Route
            path="/projectinvoice"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <ProjectInvoice />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectinvoice/add"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProjectInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectinvoice/edit/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProjectInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectinvoice/process/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProjectInvoice process />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectinvoice/createinvoice/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProjectInvoice createinvoice />
              ) : (
                // <FormCreateprojectInvoice edit />
                <Login />
              )
            }
          />
          <Route
            path="/projectinvoice/print/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PrintProjectInvoice />
              ) : (
                <Navigate to="/projectinvoice" />
              )
            }
          />
          {/* PROJECTS - Project Sales */}
          <Route
            path="/projectsales"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <ProjectSales />
              ) : (
                <Login />
              )
            }
          />
          {/* PROJECTS - Project Expense */}
          <Route
            path="/projectexpense"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <ProjectExpenses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectexpense/add"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FormProjectExpenses add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectexpense/edit/:id"
            element={
              token && type === "admin" ? (
                // (type === "admin" ||
                //     type === "franchise_officer") ? (
                <FormProjectExpenses edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectexpense/view/:id/:status"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <ViewProjectExpenses />
              ) : (
                <Login />
              )
            }
          />
          {/* SALES REPORT */}
          <Route
            path="/receivablesagingreport"
            element={
              token && type === "admin" ? (
                <ReceivablesAgingReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesreport"
            element={
              token && type === "admin" ? (
                <MonthlyReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/receivablesagingreport/print/:id"
            element={
              token && type === "admin" ? (
                <PrintReceivablesAgingReport />
              ) : (
                <Login />
              )
            }
          />
          {/* EXPENSES REPORT */}
          {/* <Route
            path="/payablesagingreport"
            element={
              token && type === "admin" ? (
                <PayablesAgingReport />
              ) : (
                <Login />
              )
            }
          /> */}
          <Route
            path="/expensereport/:datefrom/:dateto"
            element={
              token && type === "admin" ? (
                <ExpenseReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/incomestatementreport"
            element={
              token && type === "admin" ? (
                <IncomeStatementReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/expensebytype"
            element={
              token && type === "admin" ? (
                <ExpenseByType />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/expensebydate"
            element={
              token && type === "admin" ? (
                <ExpenseByDate />
              ) : (
                <Login />
              )
            }
          />
          {/* FRANCHISE - Franchise sales Report */}
          <Route
            path="/franchise_sales_report"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseSalesReport />
              ) : (
                <Login />
              )
            }
          />
          {/* FRANCHISE - Monthly Sales Billing */}
          <Route
            path="/franchisebilling"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseBilling />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchisebilling/reports"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseBilling reports />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchisebilling/add/"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseBillingForm add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchisebilling/edit/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseBillingForm edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/franchisebilling/view/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <FranchiseBillingView add />
              ) : (
                <Login />
              )
            }
          />
          {/* FRANCHISE - Receivables */}
          <Route
            path="/receivables"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <Receivables />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/receivables/print/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PrintReceivable />
              ) : (
                <Login />
              )
            }
          />
          {/* FRANCHISE - Payments */}
          <Route
            path="/payments"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <Payments />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/payments/print/:id"
            element={
              token && (type === "admin" || type === "franchise_officer") ? (
                <PaymentsPrint />
              ) : (
                <Login />
              )
            }
          />
          {/* -------------------------------------------------- */}
          {/* PURCHASES */}
          {/* PURCHASES - Purchase Order Register */}
          <Route
            path="/purchaseorders"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PurchaseOrders />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseorders/add/:shopType"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <AddPurchaseOrder />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseorders/edit/:shopType/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <EditPurchaseOrder />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseorders/review/:state/:shopType/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <ReviewPurchaseOrder />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseorders/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <PrintPurchaseOrder />
              ) : (
                <Login />
              )
            }
          />
          {/* PURCHASES - Purchase Invoice Register */}
          <Route
            path="/purchaseinvoices"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <PurchaseInvoices />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseinvoices/add/:po_id/:shopType"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <FormPurchaseInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseinvoices/edit/:pi_id/:shopType"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <FormPurchaseInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseinvoices/print/:id/:shopType"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <PrintPurchaseInvoice />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseinvoices/pay-check"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff" ||
                type === "purchasing_staff") ? (
                <PayPurchaseInvoice />
              ) : (
                <Login />
              )
            }
          />
          {/* PURCHASES - Pay Supplier */}
          <Route
            path="/paysuppliers"
            element={token ? <PaySuppliers /> : <Login />}
          />
          <Route
            path="/paysuppliers/add/:type/:shopType"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <FormPaySupplier add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/paysuppliers/edit/:id/:type/:shopType"
            element={
              token && type === "admin" ? (
                <FormPaySupplier edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/paysuppliers/view/:id/:type/:shopType"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ViewPaySupplier />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/paysuppliers/view-invoice/:id/:shopType/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ViewInvoice />
              ) : (
                <Login />
              )
            }
          />
          {/* SUPPLIES */}
          <Route
            path="/suppliesexpenses"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <SuppliesExpenses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/suppliesexpenses/add"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <FormSuppliesExpenses add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/suppliesexpenses/review/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ReviewSuppliesExpense />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/suppliesexpenses/edit/:type/:id"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <FormSuppliesExpenses edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/suppliesexpenses/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PrintSuppliesExpense />
              ) : (
                <Login />
              )
            }
          />
          {/* SUPPLIES -- Purchase Order Register */}
          <Route
            path="/se/purchaseorders"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PO />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseorders/add"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <AddPO />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseorders/edit/:id"
            element={
              token && type === "admin" ? <EditPO /> : <Login />
            }
          />
          <Route
            path="/se/purchaseorders/review/:state/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ReviewPO />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseorders/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PrintPO />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseinvoices"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PI />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseinvoices/add/:po_id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <SEFormPurchaseInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseinvoices/edit/:pi_id"
            element={
              token && type === "admin" ? (
                <SEFormPurchaseInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseinvoices/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PrintPI />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/purchaseinvoices/pay-check"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PayPI />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/paysuppliers"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <PaySuppliersE />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/paysuppliers/add/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <FormPaySupplierE add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/paysuppliers/edit/:id/:type"
            element={
              token && type === "admin" ? (
                <FormPaySupplierE edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/paysuppliers/view/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ViewPaySupplierE />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/se/paysuppliers/view-invoice/:id/:bank/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ViewInvoiceSe />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemlists"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "franchise_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <ItemLists />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemlists/branch-adjustment/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <BranchAdjustment />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemlists/itemdetails/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ItemDetails2 />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemlists/history/:branch_id/:item_id/:item_unit_id/:unit"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "franchise_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ItemHistory />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemhistorysearch/"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "franchise_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ItemHistorySearch />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/lowonstock"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "franchise_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "purchasing_staff") ? (
                <LowOnStock />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <Transfers />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/incomingtransfers"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <IncomingTransfers />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/view/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ViewTransfer />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/review/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ReviewTransfer />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/add/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <FormTransfer add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/edit/:id/:type"
            element={
              token && type === "admin" ? (
                <FormTransfer edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/add/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <FormTransfer request />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/receive/:type/:id"
            element={
              token &&
              (type === "admin" ||
                type === "commissary_officer" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <FormReceiveTransfer edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/receive/:type/:id"
            element={
              token && type === "admin" ? (
                <FormReceiveTransfer edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transfers/receive/view/:id"
            element={
              token && type === "admin" ? (
                <ViewTransferReceive edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/requests"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <Requests />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/requests/view/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer") ? (
                <ViewRequests />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/branchinventory"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "purchasing_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <BranchInventory />
              ) : (
                <Login />
              )
            }
          />
          {/* <Route
                        path="/itemhistory"
                        element={
                            token &&
                            (type === "admin" ||
                                type === "inventory_officer" ||
                                type === "purchasing_officer" ||
                                type === "operations_manager" ||
                                type === "fielder_officer_1" ||
                                type === "fielder_officer_2") ? (
                                <ItemHistoryFilter />
                            ) : (
                                <Login />
                            )
                        }
                    /> */}
          {
            <Route
              path="/adjustments"
              element={
                token &&
                (type === "admin" ||
                  type === "franchise_officer" ||
                  type === "inventory_officer" ||
                  type === "commissary_officer" ||
                  type === "purchasing_officer") ? (
                  <Adjustments />
                ) : (
                  <Login />
                )
              }
            />
          }
          {
            <Route
              path="/storeadjustments"
              element={
                token &&
                (type === "admin" ||
                  type === "franchise_officer" ||
                  type === "inventory_officer" ||
                  type === "commissary_officer" ||
                  type === "purchasing_officer") ? (
                  <StoreAdjustments />
                ) : (
                  <Login />
                )
              }
            />
          }
          <Route
            path="/adjustments/view/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "commissary_officer" ||
                type === "purchasing_officer") ? (
                <ViewAdjustment />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/buildItem"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "commissary_officer" ||
                type === "purchasing_officer") ? (
                <BuildItem />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/buildItem/:id"
            element={
              token &&
              (type === "admin" ||
                type === "inventory_officer" ||
                type === "commissary_officer" ||
                type === "purchasing_officer") ? (
                <BuildItemView />
              ) : (
                <Login />
              )
            }
          />
          {/* -------------------------------------------------- */}
          {/* STORE REPORTS */}
          <Route
            path="/dailyattendancelist"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "hr_officer" ||
                type === "fielder_officer_2") ? (
                <DailyAttendanceList />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyattendancelist/view/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "hr_officer" ||
                type === "fielder_officer_2") ? (
                <ViewDailyAttendanceList />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyattendancelist/view/employeehours/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "hr_officer" ||
                type === "fielder_officer_2") ? (
                <EmployeeHours />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/employeehours"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "hr_officer" ||
                type === "fielder_officer_2") ? (
                <EmployeeHours />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailycashcount"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <DailyCashCounts />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailycashcount/view/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <ViewDailyCashCount />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyexpenses"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <DailyExpenses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyexpenses/view/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <ViewDailyExpenses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyexpenses/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <PrintDailyExpenses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailysales"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <DailySales />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailysales/view/:id"
            // path="/dailysales/view/"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <ViewDailySales />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailysales/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <PrintDailySales />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailywastage"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <DailyWastage />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailywastage/view/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <ViewDailyWastage />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/wastagereports"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <WastageReports />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/customerpaymentreports"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <CustomerPaymentsReports />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyorders"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <DailyOrders />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dailyorders/branch/:id"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <BranchDailyOrders />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/storedeposit"
            element={
              token &&
              (type === "admin" ||
                type === "accounts_officer" ||
                type === "operations_manager" ||
                type === "fielder_officer_1" ||
                type === "fielder_officer_2") ? (
                <StoreDeposit />
              ) : (
                <Login />
              )
            }
          />
          {/* -------------------------------------------------- */}
          {/* OFFICE REPORTS */}
          <Route
            path="/payables"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Payables />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/totalsales"
            element={
              token && type === "admin" ? <TotalSales /> : <Login />
            }
          />
          <Route
            path="/franchiseesalesreport"
            element={
              token && type === "admin" ? (
                <FranchiseeSalesReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/salesbyitem"
            element={
              token && type === "admin" ? <SalesByItem /> : <Login />
            }
          />
          <Route
            path="/listofstores"
            element={
              token && type === "admin" ? <ListOfStores /> : <Login />
            }
          />
          <Route
            path="/listofbranches"
            element={
              token && type === "admin" ? (
                <ListOfBranches />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/purchaseditems"
            element={
              token && type === "admin" ? (
                <PurchasedItemsSummary />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transferhistory"
            element={
              token && type === "admin" ? (
                <TransferHistory />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/transferhistory/view/:id"
            element={
              token && type === "admin" ? (
                <ViewTransferHistory />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/productionreport"
            element={
              token && type === "admin" ? (
                <ProductionReport />
              ) : (
                <Login />
              )
            }
          />
          {/* -------------------------------------------------- */}
          {/* MANAGE */}
          <Route
            path="/suppliers"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Suppliers />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/distributors"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Distributor />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/distributors/form"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <DistributorForm />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/distributors/print/:id"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <PrintDistributor />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/distributors/billing_statement"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <DistributorGenerateBilling />
              ) : (
                <Login />
              )
            }
          />
          {/* <Route
            path="/distributor_billings"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <DistributorBillings />
              ) : (
                <Login />
              )
            }
          /> */}
          {/* <Route
            path="/distributor_billings/view/:id"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <ViewDistributorBilling />
              ) : (
                <Login />
              )
            }
          /> */}
          <Route
            path="/subscription_billings"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <SubscriptionBillings />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/subscription_billings/billing_statement"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <GenerateSubscriptionBilling />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/subscription_billings/view/:id"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <ViewSubscriptionBilling />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/vendors"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Vendors />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/banks"
            element={
              token && type === "admin" ? <Banks /> : <Login />
            }
          />
          <Route
            path="/items"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Items />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projects"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <Branches />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/warehouses"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <Warehouses />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/branchgroup"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <BranchGroup />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/inventorylevel"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <InventoryLevel />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/inventorylevel/add/:type"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <FormInventoryLevel add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/inventorylevel/edit/:id/:type"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <FormInventoryLevel edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/inventorylevel/add"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <InventoryLevel />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/products"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Products />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/itemprices"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "franchise_officer") ? (
                <ItemPrices />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/dsritems"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <DsrItems />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pricelevels"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <PriceLevels />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pricelevels/add/:destination"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <AddPriceLevel />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pricelevels/edit/:id/:destination"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <EditPriceLevel />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pricelevels/view/:id/:destination"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <ViewPriceLevel />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/users"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Users />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/forwarders"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <Forwarders />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/expensetypes"
            element={
              token && (type === "admin" || type === "purchasing_officer") ? (
                <ExpenseTypes />
              ) : (
                <Login />
              )
            }
          />
          CUSTOMER
          <Route
            path="/customer"
            element={
              token && (type === "admin" || type === "hr_officer") ? (
                <Customer />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "office_staff") ? (
                <PettyCash />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashin"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "office_staff") ? (
                <PettyCashCashInForm add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashin/:id/edit"
            element={
              token && (type === "admin" || type === "office_staff") ? (
                <PettyCashCashInForm edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashin/:id"
            element={
              token && (type === "admin" || type === "office_staff") ? (
                <PettyCashCashInView />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashout"
            element={
              token &&
              (type === "admin" ||
                type === "purchasing_officer" ||
                type === "office_staff") ? (
                <PettyCashCashOutForm add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashout/:id/edit"
            element={
              token && (type === "admin" || type === "office_staff") ? (
                <PettyCashCashOutForm edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/pettycash/cashout/:id"
            element={
              token && (type === "admin" || type === "office_staff") ? (
                <PettyCashCashOutView />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
